export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const capitaliseName = (name) => {
  if (name) {
    return name[0].toUpperCase() + name.substring(1);
  }
  return '';
};
