import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { PlayPageWrapper, PlayHeader, PlayContainer } from './Style';
import { Playlist, AudioPlayer } from '../../components';

import { capitaliseName } from '../../lib/utils';

import { bucketConfig } from '../../lib/bucketConfig';
import { availableNames } from '../../lib/availableNames';
import { stories } from '../../lib/stories';

const Play = () => {
  const navigate = useNavigate();

  // check query string
  const [searchParams] = useSearchParams();
  const [selectedName, setSelectedName] = useState('');
  const [selectedAudio, setSelectedAudio] = useState('');
  const [selectedStory, setSelectedStory] = useState('');

  // callback function to pass to player for ended handling
  const trackEndHandler = () => {
    // track event for finish
    window.gtag('event', 'finish_listen', {
      event_category: 'Step2',
      event_label: selectedName,
    });
  };

  // check something passed in via query string
  useEffect(() => {
    const queryStringName = searchParams.get('selected_name');
    const queryStringStory = searchParams.get('selected_story');

    if (!queryStringName) {
      navigate('/profile');
      return false;
    }

    // check name available
    if (
      availableNames.some(
        (x) => x.toLowerCase() === queryStringName.toLowerCase()
      )
    ) {
      setSelectedName(queryStringName);
    } else {
      navigate('/profile');
      return false;
    }

    // check story selected or default
    setSelectedStory(stories[0]['id']);
    if (queryStringStory) {
      stories.every((story) => {
        if (queryStringStory === story.id) {
          setSelectedStory(queryStringStory);
          return false;
        } else {
          return true;
        }
      });
    }
  }, [searchParams, navigate]);

  // selected story changed via query string
  useEffect(() => {
    if (selectedStory && selectedName) {
      console.log(selectedName, selectedStory);
      setSelectedAudio(
        `${
          bucketConfig.bozoJones
        }${selectedStory}/${selectedStory}-${selectedName.toLowerCase()}.mp3`
      );

      window.gtag(
        'event',
        'select_story_' + selectedStory.replaceAll('-', '_'),
        {
          event_category: 'play_screen',
          event_label: selectedName,
        }
      );
    }
  }, [selectedStory, selectedName]);

  return (
    <PlayPageWrapper>
      <Helmet>
        <title>Play stories for {capitaliseName(selectedName)} | Kidcast</title>
      </Helmet>
      <PlayHeader>
        <h1>Stories for <span>{capitaliseName(selectedName)}</span></h1>
      </PlayHeader>
      <PlayContainer>
        <Playlist
          stories={stories}
          selectedStory={selectedStory}
          selectedName={selectedName}
        />
        <AudioPlayer
          selectedAudio={selectedAudio}
          selectedName={selectedName}
          selectedStory={selectedStory}
          onEndedCallback={trackEndHandler}
          fixedPosition={true}
        />
      </PlayContainer>
    </PlayPageWrapper>
  );
};

export default Play;
