import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';

import { Button, AccordionContainer } from '../../components';
import { CollectionFaqContainer } from './Style';

const CollectionFaq = () => {
  const navigate = useNavigate();
  return (
    <CollectionFaqContainer>
      <AccordionContainer>
        <h2>Audio Storybook FAQ</h2>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="collection-faq-1"
          >
            <h3>What stories are included in the Audio Storybook?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              There are eight original stories included in the Kidcast Audio
              Storybook:
            </p>
            <ul>
              <li>The Treasure Hunt</li>
              <li>The Birthday Cake</li>
              <li>The Lost Toy</li>
              <li>The Special Delivery</li>
              <li>The Rainy Day</li>
              <li>The New Friend</li>
              <li>The Balloon Trick</li>
              <li>The Fruit Trap</li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="collection-faq-2"
          >
            <h3>Who is Bozo Jones?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Bozo Jones can be anyone or anything! It's a blank canvas your
              child's imagination will populate while they listen to the
              stories. The character is an equal - helping your child explore,
              observe, experiment and solve problems in a friendly and
              supportive way. Asking your child what Bozo Jones looks like is a
              fascinating window into the incredible wonder of a child's mind.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="collection-faq-3"
          >
            <h3>How long is each story?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Each story runs for around five minutes, which is just the right
              length for a young child's attention span. They are uninterrupted
              by ads or breaks and designed to be consumed again and again.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="collection-faq-4"
          >
            <h3>Are the stories educational?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Yes! Kidcast stories are designed to enrich learning based on
              Australia’s nationally-approved learning frameworks. They are used
              in primary classrooms and by early-childhood educators to help
              children:
            </p>
            <ul>
              <li>develop a strong sense of identity;</li>
              <li>connect with and contribute to their world;</li>
              <li>develop a strong sense of wellbeing;</li>
              <li>become confident and involved learners;</li>
              <li>develop as effective communicators.</li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
      <br />
      <Button
        variation="secondary"
        variant="contained"
        onClick={() => {
          window.gtag('event', 'click_learn_more', {
            event_category: 'landing_screen',
            event_label: 'Learn More',
          });
          navigate(`/about-us`);
        }}
      >
        Click here to learn more
      </Button>
    </CollectionFaqContainer>
  );
};

export default CollectionFaq;
