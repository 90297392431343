import styled from '@emotion/styled';

export const NotifyContainer = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  padding: 0 40px 20px 40px;

  @media only screen and (min-width: 768px) {
    width: 768px;
  }

  &.active {
    opacity: 1;
  }

  &.secondary {
    opacity: 1;
    padding: 0;

    p {
      font-family: 'Quicksand', sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      color: #241539;
      line-height: 2.3rem;

      a {
        font-family: 'Quicksand', sans-serif;
        text-decoration: underline;
      }

      strong {
        font-family: 'Quicksand', sans-serif;
      }
    }

    button {
      margin-top: 20px;
    }
  }
`;
