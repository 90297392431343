import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import MenuItem from '@mui/material/MenuItem';

import { GiftDetails, GiftSelection } from './Style';
import {
  Select,
  GiftFaq,
  BuyGiftButton,
  PlainContainer,
  Footer,
  PoweredByMonikerr,
} from '../../components';

const GiftPurchase = () => {
  const { pathname } = useLocation();

  const collectionPrice = 18.99;
  const [giftQuantity, setGiftQuantity] = useState(1);
  const [giftPrice, setGiftPrice] = useState(collectionPrice);

  // quantity and pricing on input change
  const setQuantityAndPricing = (value) => {
    setGiftQuantity(value);
    setGiftPrice((value * collectionPrice).toFixed(2));
  };

  const giftQuantityOptions = [];
  for (let i = 1; i <= 10; i++) {
    giftQuantityOptions.push(
      <MenuItem value={i} key={i} sx={{ fontSize: '1.6rem' }}>
        {i}
      </MenuItem>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>Purchase as a gift! | Kidcast</title>
      </Helmet>
      <PlainContainer>
        <GiftDetails>
          <h2>Kidcast Audio Storybook - Gift Certificate</h2>
          <p>
            You can now purchase a gift certificate for the Kidcast Audio
            Storybook, which includes 8 personalised audio stories. The stories
            can be redeemed and played instantly from any device.
          </p>
          <p>
            Simply select the number of gift certificates you require and we'll
            email them to you once you complete your purchase.
          </p>
        </GiftDetails>
        <GiftSelection>
          <h3>Gift Certificate Quantity</h3>
          <p>
            <strong>Note: </strong> Gift certificates limited to 10 per
            transaction.
          </p>
          <Select
            id="gift-card-qty"
            name="gift-card-qty"
            placeholder="Enter the quantity..."
            value={giftQuantity}
            onChange={(event) => {
              setQuantityAndPricing(event.target.value);
            }}
          >
            {giftQuantityOptions}
          </Select>
          <h2>
            {giftQuantity} Gift Certificates: ${giftPrice} AUD
          </h2>
          <BuyGiftButton quantity={giftQuantity} />
        </GiftSelection>
      </PlainContainer>
      <GiftFaq />
      <Footer />
      <PoweredByMonikerr />
    </>
  );
};

export default GiftPurchase;
