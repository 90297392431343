import React, { useEffect, useState, useRef } from 'react';
import { TextField } from '@mui/material';
import Typed from 'typed.js';
import { useSpring, animated } from 'react-spring';

import { availableNames } from '../../lib/availableNames';

import Notify from '../Notify';
import ListenNowButton from '../ListenNowButton';

import {
  NameBannerOuterContainer,
  NameBannerContainer,
  BannerIntroText,
  BannerNameText,
  TypedTextContainer,
  TypedText,
  NameSelectionContainer,
  StyledAutocomplete,
  NameHelpContainer,
  NameHelpText,
} from './Style';

const NameBanner = () => {
  // know which name was picked and email entered
  const [selectedName, setSelectedName] = useState(null);
  const [enteredName, setEnteredName] = useState('');

  // whether the first step is active
  const [nameClass, setNameClass] = useState('waiting');

  // whether to show the name request form
  const [nameRequestVisible, setNameRequestVisible] = useState(false);
  const [notifyContainerClass, setNotifyContainerClass] = useState('');

  // names typing
  const [bannerTyping, setBannerTyping] = useState(true);
  const nameTypeElement = useRef(null);
  const typed = useRef(null);

  // name help display flag
  const [helpCollapsed, setHelpCollapsed] = useState(true);

  // name help appearance delay
  const nameHelpAppearDelay = 3000;

  const handleNameInputOpen = (event, value) => {
    setNameRequestVisible(false);
    setNotifyContainerClass('');
  };

  const handleNameInputChange = (event, value) => {
    // Get the filtered options for the current input value
    const filteredOptions = availableNames.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );

    // If there are no filtered options, log a message
    if (filteredOptions.length === 0) {
      console.log(`No options available for "${value}"`);
      setEnteredName(value);
      setNameRequestVisible(true);
      setNotifyContainerClass('active');
    }
  };

  const handleNameSelect = (event, value) => {
    setSelectedName(value);
    setNameRequestVisible(false);
    setNotifyContainerClass('');
  };

  const handleNameSelectClose = (event, value) => {
    //only on blur and name request visible true, clear the input
    if (value === 'blur' && nameRequestVisible) {
      // now clear the input field since this name doesn't exist
      setSelectedName(null);

      // google event
      window.gtag('event', 'entered_non_existant_name', {
        event_category: 'Step1',
        event_label: value,
      });
    }
  };

  // show help after 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setHelpCollapsed(false);
    }, nameHelpAppearDelay);
    return () => clearTimeout(timer);
  }, [nameHelpAppearDelay]);

  // when the name is changed
  useEffect(() => {
    if (selectedName) {
      setNameClass('complete');

      setEnteredName(false);
      setNameRequestVisible(false);
      setNotifyContainerClass('');
      setHelpCollapsed(true);

      // hide keyboard
      document.activeElement.blur();

      window.gtag('event', 'select_name', {
        event_category: 'Step1',
        event_label: selectedName,
      });
    } else {
      setNameClass('waiting');
    }

    // typing effect
    const typeOptions = {
      strings: availableNames,
      typeSpeed: 120,
      backSpeed: 100,
      loop: true,
      shuffle: true,
    };

    if (selectedName !== '' && typeof selectedName === 'string') {
      typeOptions.strings = [selectedName];
      typeOptions.loop = false;
    }

    typed.current = new Typed(nameTypeElement.current, typeOptions);

    return () => {
      typed.current.destroy();
    };
  }, [selectedName]);

  // animated help text
  const animationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 800 },
    delay: nameHelpAppearDelay + 400,
  });

  const AnimatedNameHelpText = animated(NameHelpText);

  return (
    <NameBannerOuterContainer>
      <NameBannerContainer>
        <BannerIntroText>Create a FREE audio story book for:</BannerIntroText>

        <BannerNameText>
          <TypedTextContainer typing={bannerTyping}>
            <TypedText ref={nameTypeElement} />
          </TypedTextContainer>

          <NameSelectionContainer>
            <StyledAutocomplete
              disablePortal
              clearOnBlur
              className={nameClass}
              id="name-input"
              options={availableNames}
              onOpen={handleNameInputOpen}
              onInputChange={handleNameInputChange}
              onChange={handleNameSelect}
              onClose={handleNameSelectClose}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder=""
                  onFocus={() => {
                    setBannerTyping(false);
                  }}
                />
              )}
              noOptionsText="We don't have that name yet, use the form below to request this name."
              value={selectedName}
            />
          </NameSelectionContainer>
        </BannerNameText>

        {!selectedName && (
          <NameHelpContainer isCollapsed={helpCollapsed}>
            <AnimatedNameHelpText style={animationProps}>
              Type <span>your child's name</span> above to hear their free
              story!
            </AnimatedNameHelpText>
          </NameHelpContainer>
        )}
      </NameBannerContainer>

      {nameRequestVisible && (
        <Notify {...{ notifyContainerClass, enteredName }} />
      )}

      {selectedName && <ListenNowButton {...{ selectedName }} />}
    </NameBannerOuterContainer>
  );
};

export default NameBanner;
