import styled from '@emotion/styled';

export const GiftFaqContainer = styled.div`
  padding: 30px 20px;
  background: white;

  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    color: #4e3866;
    margin: 0 0 20px 0;
    text-align: center;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }
`;
