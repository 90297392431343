import styled from '@emotion/styled';

export const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  color: white;
  padding: 30px 30px 0 30px;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    padding: 40px 40px 0 40px;
  }
`;

export const LandingPanel = styled.div`
  width: 100%;
  margin-bottom: 20px;
  z-index: 2;
`;

export const PanelTitle = styled.div`
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-bottom: 20px;
  color: #563f7d;
  text-align: center;
`;

export const PanelImage = styled.div`
  margin-bottom: 20px;

  img {
    width: 100%;
  }
`;

export const PanelContent = styled.div`
  margin-bottom: 20px;
  color: #443058;
  font-weight: 500;
  font-family: 'Quicksand', sans-serif;

  p {
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-bottom: 15px;
  }

  ul {
    margin: 0 0 15px 0;
    padding: 0;

    li {
      font-size: 1.4rem;
      line-height: 2.2rem;
      margin: 0 0 10px 0;
      padding: 0;
      list-style: none;
    }
  }
`;

export const LandingSeperator = styled.div`
  height: 1px;
  width: 100%;
  border-top: 3px dashed #b7b5b8;
  margin: 0 0 35px 0;
`;
