export const availableNames = [
  'Aabhash',
  'Aaliyah',
  'Aaron',
  'Aarya',
  'Abbie',
  'Abby',
  'Abel',
  'Abigail',
  'Ace',
  'Ada',
  'Adam',
  'Addison',
  'Adelaide',
  'Adeline',
  'Adla',
  'Adrian',
  'Adriano',
  'Adrien',
  'Agnes',
  'Ahyan',
  'Aida',
  'Aiden',
  'Aimee',
  'Aisha',
  'Aiza',
  'Alan',
  'Alanna',
  'Albi',
  'Albie',
  'Alex',
  'Alexa',
  'Alexander',
  'Alexandra',
  'Alexia',
  'Alexis',
  'Alfie',
  'Ali',
  'Alice',
  'Alison',
  'Allan',
  'Allen',
  'Alvaro',
  'Alyssa',
  'Amada',
  'Amalia',
  'Amanda',
  'Amber',
  'Amelia',
  'Amelie',
  'Ames',
  'Amity',
  'Amy',
  'Amyra',
  'Ana',
  'Ana (Ah-na)',
  'Anastasia',
  'Anatole',
  'Anders',
  'Andrea',
  'Andrew',
  'Andy',
  'Angel',
  'Angela',
  'Angus',
  'Anita',
  'Ann',
  'Anna',
  'Annabelle',
  'Anne',
  'Anneke',
  'Annette',
  'Annie',
  'Annika',
  'Anthony',
  'Antonia',
  'Antony',
  'Anza',
  'Apollo',
  'Ar-Raiyan',
  'Archer',
  'Archie',
  'Areen',
  'Ari',
  'Aria',
  'Ariana',
  'Arianna',
  'Arley',
  'Arlo',
  'Arrow',
  'Arthur',
  'Artie',
  'Arty',
  'Asela',
  'Ash',
  'Ashe',
  'Asher',
  'Ashlee',
  'Ashleigh',
  'Ashley',
  'Ashton',
  'Aspen',
  'Atlas',
  'Atlee',
  'Aubrey',
  'Auden',
  'Audie',
  'Audrey',
  'Augustus',
  'Aurora',
  'Austin',
  'Autumn',
  'Ava',
  'Avery',
  'Axel',
  'Axie',
  'Axil',
  'Axton',
  'Ayla',
  'Bailey',
  'Balder',
  'Bambi',
  'Banks',
  'Barbara',
  'Bari',
  'Barry',
  'Bas',
  'Batel',
  'Baxter',
  'Bea',
  'Bear',
  'Beatrice',
  'Beau',
  'Beaumont',
  'Beckett',
  'Beckham',
  'Bee',
  'Belinda',
  'Bella',
  'Belle',
  'Ben',
  'Benita',
  'Benjamin',
  'Bennet',
  'Benny',
  'Benson',
  'Bentley',
  'Bert',
  'Betsy',
  'Beverley',
  'Beverly',
  'Bianca',
  'Bianka',
  'Bill',
  'Billie',
  'Billy',
  'Birdie',
  'Blake',
  'Blippy',
  'Bob',
  'Bobbi',
  'Bobby',
  'Bodhi',
  'Bonnie',
  'Boomer',
  'Bowen',
  'Bowie',
  'Brad',
  'Braxton',
  'Brayden',
  'Braydon',
  'Breanna',
  'Brendan',
  'Brett',
  'Brian',
  'Brianna',
  'Bridie',
  'Bronwen',
  'Bronwyn',
  'Brooklyn',
  'Brooks',
  'Bruce',
  'Bryan',
  'Burt',
  'Caesar',
  'Caiden',
  'Cairo',
  'Caleb',
  'Cameron',
  'Camila',
  'Camilla',
  'Camilla Daisy',
  'Canon',
  'Carin',
  'Carina',
  'Carissa',
  'Carl',
  'Carlo',
  'Carlos',
  'Carlton',
  'Carmen',
  'Carol',
  'Caroline',
  'Carolyn',
  'Carter',
  'Cash',
  'Casper',
  'Cass',
  'Cate',
  'Catherine',
  'Cathryn',
  'Cathy',
  'Catie',
  'Cato',
  'Catrina',
  'Cayden',
  'Cayson',
  'Ceili',
  'Celeste',
  'Chandler',
  'Charles',
  'Charli',
  'Charlie',
  'Charlotte',
  'Charly',
  'Chase',
  'Chelsea',
  'Cheryl',
  'Chicago',
  'Chloe',
  'Chris',
  'Chrissy',
  'Christian',
  'Christie',
  'Christina',
  'Christine',
  'Christopher',
  'Chuck',
  'Chucky',
  'Claire',
  'Clara',
  'Cleo',
  'Coco',
  'Coda',
  'Cohen',
  'Colin',
  'Colleen',
  'Colton',
  'Conan',
  'Connor',
  'Conor',
  'Coop',
  'Cooper',
  'Corey',
  'Cowan',
  'Craig',
  'Crew',
  'Cruz',
  'Cucumberface',
  'Cullin',
  'Cypress',
  'Cyrus',
  'Daddy',
  'Dafne',
  'Daisy',
  'Dale',
  'Damian',
  'Damien',
  'Dan',
  'Dana',
  'Dani',
  'Daniel',
  'Danielle',
  'Danilo',
  'Danny',
  'Daphne',
  'Darcy',
  'Daren',
  'Darrell',
  'Darren',
  'Darryl',
  'Daryl',
  'Dave',
  'David',
  'Davis',
  'Dean',
  'Deb',
  'Debbie',
  'Deborah',
  'Debra',
  'Declan',
  'Deetya',
  'Delilah',
  'Denise',
  'Dennis',
  'Deon',
  'Derek',
  'Derrick',
  'Dev',
  'Devin',
  'Dhriti',
  'Diana',
  'Diane',
  'Dimitri',
  'Dion',
  'Dom',
  'Domenica',
  'Dominic',
  'Don',
  'Donna',
  'Doug',
  'Dougie',
  'Douglas',
  'Dream',
  'Drew',
  'Driti',
  'Dylan',
  'Eamon',
  'Easton',
  'Ed',
  'Eddie',
  'Eddy',
  'Eden',
  'Edgar',
  'Edith',
  'Edward',
  'Egon',
  'Eissa',
  'Eleanor',
  'Elena',
  'Eli',
  'Elias',
  'Elijah',
  'Elise',
  'Eliza',
  'Elizabeth',
  'Ella',
  'Ellie',
  'Elliot',
  'Eloise',
  'Elsie',
  'Em',
  'Emery',
  'Emilia',
  'Emily',
  'Emma',
  'Endria',
  'Ennis',
  'Ensley',
  'Enzo',
  'Eric',
  'Erin',
  'Ernie',
  'Eros',
  'Esme',
  'Essex',
  'Estelle',
  'Ethan',
  'Eva',
  'Evaliah',
  'Evan',
  'Evangeline',
  'Eve',
  'Evelyn',
  'Evie',
  'Evleen',
  'Ezer',
  'Ezra',
  'Fabian',
  'Faith',
  'Fanny',
  'Fatima',
  'Felicia',
  'Felipe',
  'Felix',
  'Fergus',
  'Fidel',
  'Fil',
  'Finley',
  'Finn',
  'Fiona',
  'Fletcher',
  'Fleur',
  'Flo',
  'Flora',
  'Florence',
  'Flynn',
  'Forest',
  'Forester',
  'Frances',
  'Francis',
  'Frank',
  'Frankie',
  'Franklin',
  'Freddie',
  'Freddy',
  'Freya',
  'G',
  'Gabby',
  'Gabriel',
  'Gabriella',
  'Gaby',
  'Gail',
  'Garry',
  'Gary',
  'Gavin',
  'Genesis',
  'Genevieve',
  'Geoff',
  'Geoffrey',
  'George',
  'Georgia',
  'Georgie',
  'Gia',
  'Giannis',
  'Gilbert',
  'Gina',
  'Glen',
  'Glenn',
  'Golden',
  'Gordon',
  'Gowri',
  'Grace',
  'Gracie',
  'Graeme',
  'Graham',
  'Grant',
  'Grayson',
  'Greg',
  'Grey',
  'Greyson',
  'Griffin',
  'Gunner',
  'Hailey',
  'Hallie',
  'Hamish',
  'Hank',
  'Hannah',
  'Hans',
  'Harald',
  'Harley',
  'Harlow',
  'Harold',
  'Harper',
  'Harriet',
  'Harrison',
  'Harry',
  'Hart',
  'Harvey',
  'Haskell',
  'Hayden',
  'Hayley',
  'Hazel',
  'Heather',
  'Hector',
  'Heidi',
  'Heiress',
  'Hektor',
  'Helen',
  'Helena',
  'Hemingway',
  'Hendrik',
  'Hendrix',
  'Henry',
  'Holden',
  'Holland',
  'Holly',
  'Hope',
  'Hridhan',
  'Hudson',
  'Hugh',
  'Hugo',
  'Hunter',
  'Huxley',
  'Ian',
  'Ida',
  'Iluka',
  'Imogen',
  'Imran',
  'Indi',
  'Indiana',
  'Indie',
  'Indigo',
  'Inez',
  'Inga',
  'Isaac',
  'Isabel',
  'Isabella',
  'Isabelle',
  'Isaiah',
  'Isiah',
  'Isla',
  'Issac',
  'Ivey',
  'Ivy',
  'Izzy',
  'Jace',
  'Jack',
  'Jackie',
  'Jackson',
  'Jacky',
  'Jacob',
  'Jacqui',
  'Jacquie',
  'Jade',
  'Jaden',
  'Jagger',
  'Jake',
  'Jakob',
  'James',
  'Jameson',
  'Jamie',
  'Jan (Ya-hn)',
  'Jana',
  'Jane',
  'Janet',
  'Janette',
  'Janine',
  'Jasmine',
  'Jason',
  'Jasper',
  'Javier',
  'Jax',
  'Jaxon',
  'Jayden',
  'Jeanie',
  'Jeff',
  'Jeffrey',
  'Jenni',
  'Jennifer',
  'Jenny',
  'Jeremiah',
  'Jeremy',
  'Jess',
  'Jesse',
  'Jessica',
  'Jessie',
  'Jett',
  'Jill',
  'Jim',
  'Jimmy',
  'Jo',
  'Joan',
  'Joanne',
  'Jodie',
  'Joe',
  'Joel',
  'Joey',
  'John',
  'Johnny',
  'Jon',
  'Jonathan',
  'Jonelle',
  'Jono',
  'Jordan',
  'Jose',
  'Joseph',
  'Josephine',
  'Josh',
  'Joshua',
  'Joy',
  'Jru',
  'Jude',
  'Judith',
  'Judy',
  'Jules',
  'Julia',
  'Julian',
  'Julie',
  'Justice',
  'Justin',
  'Kai',
  'Kaio',
  'Kamila',
  'Kamilla',
  'Karen',
  'Kari',
  'Karin',
  'Karl',
  'Karmen',
  'Kate',
  'Katherine',
  'Kathryn',
  'Kathy',
  'Katie',
  'Kato',
  'Katrina',
  'Katy',
  'Kay',
  'Kayden',
  'Kaylee',
  'Kayleigh',
  'Keira',
  'Keith',
  'Kel',
  'Kellie',
  'Kelly',
  'Ken',
  'Kennedy',
  'Kenzo',
  'Kerrie',
  'Kerry',
  'Kevin',
  'Kiara',
  'Kiki',
  'Kim',
  'Kingston',
  'Kira',
  'Kirra',
  'Kodah',
  'Kohen',
  'Kokomi',
  'Kris',
  'Kristina',
  'Kristy',
  'Kulture',
  'Kylie',
  'Lacey',
  'Lachie',
  'Lachlan',
  'Lachy',
  'Lacie',
  'Laila',
  'Laine',
  'Lana',
  'Landon',
  'Lara',
  'Lars',
  'Laura',
  'Lauren',
  'Layla',
  'Lazlo',
  'Lea',
  'Leah',
  'Leanne',
  'Lee',
  'Leia',
  'Leif',
  'Leigh',
  'Leila',
  'Lena',
  'Lenny',
  'Lenon',
  'Leo',
  'Leon',
  'Leonardo',
  'Leonie',
  'Les',
  'Lesley',
  'Leslie',
  'Levi',
  'Levon',
  'Lewis',
  'Lexi',
  'Leyla',
  'Liam',
  'Liberty',
  'Lil',
  'Lila',
  'Lilah',
  'Lillian',
  'Lilly',
  'Lily',
  'Lin',
  'Linc',
  'Lincoln',
  'Linda',
  'Lindi',
  'Lindsay',
  'Lindsey',
  'Lindy',
  'Lisa',
  'Liss',
  'Liv',
  'Liz',
  'Lizzy',
  'Logan',
  'Lola',
  'London',
  'Lorraine',
  'Lottie',
  'Louis',
  'Louise',
  'Love',
  'Loyal',
  'Luca',
  'Lucas',
  'Lucia',
  'Lucie',
  'Lucien',
  'Lucy',
  'Luka',
  'Lukas',
  'Luke',
  'Lula',
  'Luli',
  'Lulu',
  'Luna',
  'Lydia',
  'Lyla',
  'Lyn',
  'Lynne',
  'Lyric',
  'Mabel',
  'Mac',
  'Mackenzie',
  'Macy',
  'Maddie',
  'Maddison',
  'Maddy',
  'Madeleine',
  'Madeline',
  'Madelyn',
  'Madison',
  'Maeve',
  'Maggie',
  'Magnus',
  'Maite',
  'Major',
  'Makoto',
  'Malcolm',
  'Malina',
  'Malva',
  'Mandy',
  'Marc',
  'Marcia',
  'Marco',
  'Marcus',
  'Maree',
  'Margaret',
  'Maria',
  'Mariam',
  'Marie',
  'Mark',
  'Marlee',
  'Marley',
  'Marlo',
  'Marsha',
  'Marshall',
  'Martin',
  'Marvel',
  'Mary',
  'Maryam',
  'Mason',
  'Mateo',
  'Mathew',
  'Matilda',
  'Matt',
  'Matthew',
  'Matty',
  'Maureen',
  'Maverick',
  'Max',
  'Maxie',
  'Maxime',
  'Maxwell',
  'Maya',
  'Megan',
  'Meghan',
  'Melanie',
  'Melissa',
  'Memphis',
  'Meredith',
  'Mia',
  'Michael',
  'Michelle',
  'Mick',
  'Mikayla',
  'Mike',
  'Mikey',
  'Mila',
  'Miles',
  'Milla',
  'Millie',
  'Milo',
  'Mimmo',
  'Miranda',
  'Mitch',
  'Mitchell',
  'Moahi',
  'Moe',
  'Mohammad',
  'Mohammed',
  'Molly',
  'Mommy',
  'Montague',
  'Monte',
  'Montgomery',
  'Muhammad',
  'Muhammed',
  'Mummy',
  'Murray',
  'Nadia',
  'Nadja',
  'Naish',
  'Naja',
  'Nanette',
  'Naomi',
  'Napoleon',
  'Narelle',
  'Nat',
  'Natalia',
  'Natalie',
  'Natarsha',
  'Natasha (Na-tar-sha)',
  'Natasha (Na-tash-a)',
  'Nate',
  'Nath',
  'Nathan',
  'Nathaniel',
  'Ned',
  'Neil',
  'Nelly',
  'Nelson',
  'Nicholas',
  'Nick',
  'Nicki',
  'Nicky',
  'Nicolas',
  'Nicole',
  'Nigel',
  'Nika',
  'Nikole',
  'Nina',
  'Nixie',
  'Nixon',
  'Noah',
  'Noel',
  'Nolan',
  'Nora',
  'Norman',
  'North',
  'Nova',
  'Ode',
  'Odette',
  'Odie',
  'Olav',
  'Olga',
  'Olive',
  'Oliver',
  'Olivia',
  'Ollie',
  'Olympia',
  'Omar',
  'Onyx',
  'Oscar',
  'Otis',
  'Owen',
  'Pace',
  'Paige',
  'Paisley',
  'Pam',
  'Pamela',
  'Pamir',
  'Parker',
  'Pat',
  'Patricia',
  'Patrick',
  'Patrik',
  'Patty',
  'Paul',
  'Paula',
  'Pauline',
  'Paulo',
  'Pax',
  'Payton',
  'Pearl',
  'Pedro',
  'Penelope',
  'Penny',
  'Peter',
  'Peyton',
  'Phil',
  'Philip',
  'Philippe',
  'Phillip',
  'Phoebe',
  'Phoenix',
  'Pieter',
  'Piper',
  'Pippa',
  'Pixie',
  'Poppy',
  'Portia',
  'Prep AK',
  'Presley',
  'Prince',
  'Quinn',
  'Racer',
  'Rachael',
  'Rachel',
  'Raf',
  'Rafa',
  'Rafael',
  'Rainey',
  'Raja',
  'Ralf',
  'Ralph',
  'Rana',
  'Randi',
  'Randy',
  'Raph',
  'Raphael',
  'Ray',
  'Rebecca',
  'Rebekah',
  'Rei',
  'Remi',
  'Remington',
  'Remy',
  'Renee',
  'Reuben',
  'Revel',
  'Rhett',
  'Rhodes',
  'Rhonda',
  'Rian',
  'Rich',
  'Richard',
  'Richie',
  'Rick',
  'Riley',
  'Ripley',
  'Ritchie',
  'Riva',
  'River',
  'Robert',
  'Robin',
  'Robinson',
  'Robyn',
  'Rocco',
  'Rod',
  'Rodney',
  'Roger',
  'Rohan',
  'Romeo',
  'Ron',
  'Ronan',
  'Rose',
  'Rosie',
  'Ross',
  'Roux',
  'Rowan',
  'Ruben',
  'Ruby',
  'Rumble',
  'Rumi',
  'Russell',
  'Ruth',
  'Ryan',
  'Ryder',
  'Saba',
  'Sabrina',
  'Sadie',
  'Saffron',
  'Salina',
  'Sally',
  'Sam',
  'Samantha',
  'Sammy',
  'Samuel',
  'Sandra',
  'Sandy',
  'Santiago',
  'Sarah',
  'Saskia',
  'Savannah',
  'Sawyer',
  'Scarlett',
  'Scott',
  'Sean',
  'Sebastian',
  'Sebs',
  'Selina',
  'Senan',
  'Serafina',
  'Seth',
  'Shaffer',
  'Shai',
  'Shane',
  'Shannon',
  'Sharon',
  'Shaun',
  'Shawn',
  'Sheryl',
  'Shiloh',
  'Shirley',
  'Sienna',
  'Silvia',
  'Simon',
  'Simone',
  'Sir',
  'Skylar',
  'Slate',
  'Sofia',
  'Sofie',
  'Soltan',
  'Sonny',
  'Soph',
  'Sophia',
  'Sophie',
  'Sovereign',
  'Spencer',
  'Stacey',
  'Stacy',
  'Stefanie',
  'Stella',
  'Stenton',
  'Stephanie',
  'Stephen',
  'Steven',
  'Stewart',
  'Stormi',
  'Story',
  'Strummer',
  'Stuart',
  'Sue',
  'Sully',
  'Summer',
  'Suri',
  'Susan',
  'Sutton',
  'Suzanne',
  'Sybil',
  'Sylvia',
  'Talia',
  'Tamara',
  'Tania',
  'Tanya',
  'Taylor',
  'Taz',
  'Te Mauri',
  'Ted',
  'Teddy',
  'Teema',
  'Terese',
  'Terri',
  'Terry',
  'Thea',
  'Theo',
  'Theodore',
  'Thomas',
  'Tiger',
  'Tilly',
  'Tim',
  'Timi',
  'Timmy',
  'Timothy',
  'Timur',
  'Tina',
  'Titan',
  'Toby',
  'Tom',
  'Tommy',
  'Toni',
  'Tony',
  'Torin',
  'Tracey',
  'Tracie',
  'Tracy',
  'Trevor',
  'Tripp',
  'Trish',
  'Troy',
  'True',
  'Tyler',
  'Ulrich',
  'Ulrik',
  'Ursula',
  'Valentina',
  'Vanessa',
  'Venny',
  'Vera',
  'Verner',
  'Verona',
  'Vicki',
  'Vicky',
  'Victoria',
  'Vida',
  'Vincent',
  'Vinnie',
  'Vinny',
  'Viola',
  'Violet',
  'Violette',
  'Ward',
  'Warick',
  'Warren',
  'Warwick',
  'Wayne',
  'Wei',
  'Wendy',
  'Werna',
  'Werner',
  'Westlyn',
  'Will',
  'William',
  'Willie',
  'Willow',
  'Willy',
  'Wilma',
  'Wilmar',
  'Winner',
  'Winston',
  'Wolf',
  'Wolfie',
  'Wyatt',
  'Xav',
  'Xavier',
  'Xenon',
  'Yang',
  'Yannis',
  'York',
  'Yves',
  'Yvonne',
  'Zach',
  'Zachary',
  'Zahra',
  'Zak',
  'Zane',
  'Zara',
  'Zayla',
  'Zeke',
  'Zeki',
  'Zen',
  'Zeppelin',
  'Ziggy',
  'Zoe',
  'Zoey',
  'Zwiep',
  'Zyla',
];
