import styled from '@emotion/styled';

export const SocialLinksContainer = styled.div`
  margin: 20px 0;

  h3 {
    color: #240f42;
  }
`;

export const SocialLinkList = styled.div`
  display: flex;
  gap: 30px;
  padding: 20px 0;
  align-items: center;
  flex-wrap: wrap;
`;

export const SocialLink = styled.a`
  color: #332054;
  fill: currentColor;
`;
