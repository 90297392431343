import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  Footer,
  Testimonials,
  Subscribe,
  NameBanner,
  ProductFaq,
  FeatureImageAndText,
} from '../../components';

import { HomeContainer } from './Style';

const Home = () => {
  return (
    <HomeContainer>
      <Helmet>
        <title>Kidcast Personalised Audio Storybook For Ages 2-6 Years</title>
      </Helmet>
      <NameBanner />
      <FeatureImageAndText />
      <Testimonials />
      <ProductFaq />
      <Subscribe />
      <Footer />
    </HomeContainer>
  );
};

export default Home;
