import React from 'react';

import { ReactComponent as IconInsta } from '../../assets/icon-social-instagram.svg';
import { ReactComponent as IconFacebook } from '../../assets/icon-social-facebook.svg';
import { ReactComponent as IconYoutube } from '../../assets/icon-social-youtube.svg';
import { ReactComponent as IconTikTok } from '../../assets/icon-social-tiktok.svg';

import { SocialLinksContainer, SocialLinkList, SocialLink } from './Style';

const SocialLinks = () => {
  return (
    <SocialLinksContainer>
      <h3>Follow us</h3>
      <SocialLinkList>
        <SocialLink href="https://www.instagram.com/kidcast.fm" target="_blank">
          <IconInsta />
        </SocialLink>
        <SocialLink href="https://www.facebook.com/Kidcast.fm" target="_blank">
          <IconFacebook />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/@kidcastfm" target="_blank">
          <IconYoutube />
        </SocialLink>
        <SocialLink href="https://www.tiktok.com/@kidcast.fm" target="_blank">
          <IconTikTok />
        </SocialLink>
      </SocialLinkList>
    </SocialLinksContainer>
  );
};

export default SocialLinks;
