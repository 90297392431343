import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  TextField,
  PlainContainer,
  Notify,
  AudioPreview,
  Footer,
} from '../../components';

import { availableNames } from '../../lib/availableNames';
import { bucketConfig } from '../../lib/bucketConfig';
import { stories } from '../../lib/stories';

import {
  PersonalisationHeader,
  PersonalisationForm,
  StyledAutocomplete,
  PreviewAndPurchase,
  PreviewExtraInfo,
} from './Style';

const Personalise = () => {
  const [selectedName, setSelectedName] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState('');
  const [selectedStory, setSelectedStory] = useState('');
  const [selectedNameClass, setSelectedNameClass] = useState('');
  const [enteredName, setEnteredName] = useState('');
  const [nameUnavailable, setNameUnavailable] = useState(false);

  const handleNameInputOpen = (event, value) => {
    setNameUnavailable(false);
  };

  const handleNameInputChange = (event, value) => {
    // Get the filtered options for the current input value
    const filteredOptions = availableNames.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );

    // If there are no filtered options, log a message
    if (filteredOptions.length === 0) {
      console.log(`No options available for "${value}"`);
      setEnteredName(value);
      setSelectedNameClass('');
      setNameUnavailable(true);
    }
  };

  const handleNameSelect = (event, value) => {
    setSelectedName(value);
    setSelectedNameClass('complete');
    setNameUnavailable(false);

    window.gtag('event', 'select_name', {
      event_category: 'personalisation',
      event_label: 'Select Name',
    });
  };

  const handleNameSelectClose = (event, value) => {
    //only on blur and name request visible true, clear the input
    if (value === 'blur' && nameUnavailable) {
      setSelectedName(null);
      setSelectedNameClass('');
    }
  };

  // callback event handling for listen ending
  const trackEndHandler = () => {
    console.log('Track ended');

    // track event for finish
    window.gtag('event', 'finish_preview_listen', {
      event_category: 'personalise',
      event_label: selectedName,
    });
  };

  useEffect(() => {
    const previewStorySelection = stories[0]['id'];
    setSelectedStory(previewStorySelection);

    if (selectedName !== null) {
      setSelectedAudio(
        `${
          bucketConfig.bozoJones
        }${previewStorySelection}/${previewStorySelection}-${selectedName.toLowerCase()}.mp3`
      );
    }
  }, [selectedName]);

  return (
    <>
      <Helmet>
        <title>Personalisation | Kidcast</title>
      </Helmet>
      <PlainContainer>
        <PersonalisationHeader>
          <h2>Add Child's Name</h2>
          <p>
            See the look on your child's face when they hear their name in the
            story! Select their name below and listen to their first
            personalised audio story "The Treasure Hunt".
          </p>
        </PersonalisationHeader>

        <PersonalisationForm>
          <h3>Child's Name</h3>
          <StyledAutocomplete
            disablePortal
            clearOnBlur
            id="child-name"
            options={availableNames}
            onOpen={handleNameInputOpen}
            onInputChange={handleNameInputChange}
            onChange={handleNameSelect}
            onClose={handleNameSelectClose}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Child's name..."
                className={selectedNameClass}
              />
            )}
            noOptionsText="We don't have that name yet, use the form below to request this name."
            value={selectedName}
          />
          {nameUnavailable && (
            <Notify
              notifyContainerClass="secondary"
              enteredName={enteredName}
            />
          )}
        </PersonalisationForm>

        <PreviewAndPurchase>
          <AudioPreview
            selectedAudio={selectedAudio}
            selectedName={selectedName}
            selectedStory={selectedStory}
            onEndedCallback={trackEndHandler}
          />
        </PreviewAndPurchase>

        <PreviewExtraInfo>
          <p>
            Make your child feel special with their very own Personalised Audio
            Storybook featuring 8 immersive audio stories you can play
            instantly, from any device.
          </p>
          <p>
            You can play your stories anytime, anywhere, and as many times as you like!
          </p>
        </PreviewExtraInfo>
      </PlainContainer>
      <Footer />
    </>
  );
};

export default Personalise;
