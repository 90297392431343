import { createContext, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Creates a Context object.
export const UserContext = createContext();

// A custom hook for consuming the Context in other components.
export const useUserContext = () => useContext(UserContext);

// A wrapper for the Context Provider providing access
// to the current theme (light or dark) and a function to update the theme.
export const UserProvider = ({ children }) => {
  // function to get machine ID for play tracking
  const getMachineId = () => {
    let returnMachineId = localStorage.getItem('machineId');
    if (!returnMachineId) {
      returnMachineId = uuidv4();
      localStorage.setItem('machineId', returnMachineId);
    }
    return returnMachineId;
  };

  // machine ID retrieval or set
  const machineId = getMachineId();

  return (
    <UserContext.Provider
      value={{
        machineId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
