import React from 'react';
import { Helmet } from 'react-helmet-async';

import {
  Footer,
  OtherStories,
  ProductFaq,
  FeaturedReview,
  CollectionDetails,
  CollectionFaq,
  BenefitsKids,
  BenefitsParents,
  CollectionReviews,
  WhenToPlay,
} from '../../components';

const Shop = () => {
  return (
    <>
      <Helmet>
        <title>Kidcast Personalised Audio Storybook For Ages 2-6 Years</title>
      </Helmet>
      <CollectionDetails />
      <FeaturedReview />
      <ProductFaq />
      <BenefitsKids />
      <CollectionReviews />
      <OtherStories />
      <WhenToPlay />
      <BenefitsParents title="How do parents and carers benefit from Kidcast?" />
      <CollectionFaq />
      <Footer />
    </>
  );
};

export default Shop;
