import React from 'react';
import Rating from '../Rating';
import {
  CollectionReviewsContainer,
  CollectionReview,
  ReviewTitle,
  ReviewName,
  ReviewText,
} from './Style';

const CollectionReviews = () => {
  return (
    <CollectionReviewsContainer id="reviews">
      <h2>Reviews</h2>
      <CollectionReview key="collection-review-1">
        <ReviewTitle>
          <ReviewName>Kate</ReviewName>
          <Rating stars={5} />
        </ReviewTitle>
        <ReviewText>
          "Ava's play is getting so imaginative! She can't wait to share her
          latest adventures with us."
        </ReviewText>
      </CollectionReview>
      <CollectionReview key="collection-review-2">
        <ReviewTitle>
          <ReviewName>Sarah</ReviewName>
          <Rating stars={5} />
        </ReviewTitle>
        <ReviewText>
          "I’m blown away by how much Liam's speech and vocabulary has improved.
          He's so much more confident with his words."
        </ReviewText>
      </CollectionReview>
      <CollectionReview key="collection-review-3">
        <ReviewTitle>
          <ReviewName>Blair</ReviewName>
          <Rating stars={5} />
        </ReviewTitle>
        <ReviewText>
          "These were just what Mia needed. She's playing more confidently with
          other kids and is way less needy lately."
        </ReviewText>
      </CollectionReview>
      <CollectionReview key="collection-review-4">
        <ReviewTitle>
          <ReviewName>Carly</ReviewName>
          <Rating stars={5} />
        </ReviewTitle>
        <ReviewText>
          "Charlie is expressing himself better and trying to solve his own
          problems just like he imagines in the stories. It's very impressive!"
        </ReviewText>
      </CollectionReview>
      <CollectionReview key="collection-review-5">
        <ReviewTitle>
          <ReviewName>Prue</ReviewName>
          <Rating stars={5} />
        </ReviewTitle>
        <ReviewText>
          "The change in Noah was instant. We noticed him reenacting things from
          the stories with his little sister - it was so sweet."
        </ReviewText>
      </CollectionReview>
    </CollectionReviewsContainer>
  );
};

export default CollectionReviews;
