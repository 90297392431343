import React from 'react';
import { ButtonContainer } from './Style';

const Button = ({ children, ...rest }) => {
  return (
    <ButtonContainer {...rest}>
      {children}
    </ButtonContainer>
  );
};

export default Button;
