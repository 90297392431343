import styled from '@emotion/styled';

export const BuySecondaryContainer = styled.div`
  background: #231539;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }
`;

export const BuySecondaryCard = styled.div`
  background: #342054;
  border-radius: 12px 12px 0 0;
  padding: 15px 20px;
`;

export const BuySecondaryMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 85px;
    height: 85px;
  }
  p {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.6rem;
    color: #ffffff;
    padding-left: 25px;
  }
`;

export const BuySecondaryButton = styled.a`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: #90e3b7;
  border-radius: 0 0 12px 12px;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  color: #125330;
  text-decoration: none;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(255, 255, 255, 0.34);
`;
