import React from 'react';
import { StyledMainContainer } from './Style';

const MainContainer = ({ children }) => {
  return (
    <StyledMainContainer>
      {children}
    </StyledMainContainer>
  );
};

export default MainContainer;
