import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const ButtonContainer = styled(Button)`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  line-height: 60px;
  margin-bottom: 30px;
  z-index: 9;

  &:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
  }

  &.flexible-height {
    height: auto;
    line-height: 20px;
    padding: 15px 0;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;

    span {
      font-weight: normal;
    }
  }

  @media only screen and (min-width: 768px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  ${(props) => {
    if (props.variation === 'primary') {
      return `
        background: #EB386F;
        color: #ffffff;
    
        &:hover {
          background: #EB386F;
        }
    `;
    } else if (props.variation === 'secondary') {
      return `
        background: #4B73CA;
        color: #fff;
      
        &:hover {
          background: #3667D1;
        }
    `;
    } else if (props.variation === 'tertiary') {
      return `
        background: #90E3B7;
        color: #125330;
    
        &:hover {
          background: #AFF3CF;
        }
    `;
    } else if (props.variation === 'alternative') {
      return `
        color: #534912;
        background: #FFF1BC;
    
        &:hover {
          background: #FFE276;
        }
    `;
    }
  }}
`;
