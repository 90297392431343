import React from 'react';
import { Rating } from '../../components';
import {
  FeaturedReviewContainer,
  FeaturedReviewHeader,
  FeaturedReviewTitle,
  FeaturedReviewContent,
  FeaturedReviewLink,
} from './Style';

const FeaturedReview = () => {
  return (
    <FeaturedReviewContainer>
      <FeaturedReviewHeader>
        <FeaturedReviewTitle>Featured Review</FeaturedReviewTitle>
        <Rating stars={5} />
      </FeaturedReviewHeader>
      <FeaturedReviewContent>
        <p>
          "Henry's attention span has increased significantly. We're really
          happy we discovered the stories." - Jess
        </p>
      </FeaturedReviewContent>
      <FeaturedReviewLink href="#reviews">Read more reviews</FeaturedReviewLink>
    </FeaturedReviewContainer>
  );
};

export default FeaturedReview;
