import styled from '@emotion/styled';

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  flex: 1;
  text-justify: inter-word;
  text-align: justify;
`;

export const CloseLink = styled.button`
  position: absolute;
  top: 30px;
  right: 20px;
  color: #66528C;
  border: none;
  background: none;

  svg {
    width: 15px;
    height: 15px;
  }
`;
