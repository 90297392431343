import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Card } from '../../components';

import { TermsContainer } from './Style';

const Terms = () => {
  window.scroll(0, 0);
  return (
    <TermsContainer>
      <Helmet>
        <title>Terms of Use | Kidcast</title>
      </Helmet>
      <Card>
        <h1>Terms of Use</h1>
        <p>
          In these terms, "we", "us", "our", and “Kidcast” means
          Kershaw Books and its related bodies corporate, and any 
          successors or assigns.
        </p>
        <p>
          Your access to and use of Kidcast, our social media pages, web pages
          and website (<strong>Website</strong>), mobile sites (
          <strong>mSites</strong>), and all related content (
          <strong>Content</strong>) (collectively 
          <strong>Digital Assets</strong>) is subject to these terms, our
          Privacy Policy and any other terms contained on any of our Digital
          Assets (**Terms of Use**). By accessing and using any of our Digital
          Assets, you agree to be bound by these Terms of Use.
        </p>

        <p>
          We reserve the right, in our sole discretion, to change, modify, add,
          or delete portions of these Terms of Use at any time by updating this
          posting. Your continued use of our Digital Assets after any such
          changes are posted constitutes your acceptance of the revised Terms of
          Use. You are responsible for keeping yourself updated on any changes.
        </p>

        <h2>Basic Terms</h2>

        <ul>
          <li>
            You must be 18 years or older or have your parent or guardian's
            consent to use the Digital Assets and any related services.
          </li>
          <li>
            The Digital Assets provide (among other things) an entertainment
            service by which you can interact with us online by (among other
            things) viewing, listening to and/or posting Content. We may provide
            different levels of interaction from time to time at our discretion.
          </li>
          <li>
            We reserve the right at any time to charge or amend any fees payable
            by you and to vary or amend the terms and conditions which cover
            such fees for access or use of our Digital Assets.
          </li>
          <li>
            You may be required to create an account to have access to all
            aspects of our Digital Assets. If you register with us using a
            password(s), you are responsible for keeping your password(s) secure
            and you are fully responsible for all activities that occur under
            your password(s). Kershaw Books shall not be liable for any loss that you
            incur as a result of someone else using your password, either with
            or without your knowledge. You may be held liable for any losses
            incurred by Kershaw Books, its affiliates, officers, directors,
            employees, consultants, agents, and representatives due to someone
            else’s use of your account or password. You agree to notify us
            immediately of any unauthorised use of your password(s). We reserve
            the absolute right not to issue a password to any person or entity.
          </li>
          <li>
            You agree not to alter or modify any part of our Digital Assets or
            related technologies. You will not remove any copyright, trademark
            or other proprietary notices from material found on the Digital
            Assets. Content on the Services is provided to you for your
            information and personal use only and may not be copied, reproduced,
            distributed, transmitted, broadcast, displayed, sold, licensed, or
            otherwise exploited for any other purpose. We reserve all rights not
            expressly granted in and to the Services and any and all content,
            information, materials, text, graphics, photographs, images, moving
            images, sound, and illustrations, computer code, and software
            (collectively, the “Content”).
          </li>
          <li>
            You agree not to distribute any part of the Services in any medium
            without our prior written authorisation. You further agree not to
            engage in the use, copying, or distribution of any of the Content
            other than expressly permitted herein, including any use, copying,
            or distribution of Content of third parties obtained through the
            Services for any commercial purposes.
          </li>
          <li>
            You agree not to access Content produced by us or any third party
            through any technology or means other than the playback pages of the
            websites or client applications, or other explicitly authorised
            means we may designate.
          </li>
          <li>
            You further agree not modify another website so as to falsely imply
            that it is associated with us.
          </li>
          <li>
            You agree not to use the Services for any illegal or unauthorised
            purpose. International users agree to comply with all local laws
            regarding online conduct and acceptable content.
          </li>
          <li>
            In the event of the addition of any messaging capabilities you must
            not create or submit unwanted email to any other registered members
            or use the Services to send spam.
          </li>
          <li>
            Your use of the Services must not violate any laws in your
            jurisdiction (including but not limited to copyright laws).
          </li>
          <li>
            You must not impersonate any person or falsely state or otherwise
            misrepresent your credentials, affiliation with any person, or the
            origin of any information you provide.
          </li>
          <li>
            You must not engage in unauthorised spidering, scraping, or
            harvesting of content or information, or use any other unauthorised
            automated means to compile information.
          </li>
          <li>
            You must not obtain or attempt to gain unauthorised access to other
            computer systems, materials, information or any services available
            on or through the Services.
          </li>
          <li>
            You must not use any device, software, or routine to interfere or
            attempt to interfere with the proper working of the Services or any
            activity conducted on the Services or attempt to probe, scan, test
            the vulnerability of, or breach the security of any system, device
            or network.
          </li>
          <li>
            You agree not to circumvent, reverse engineer, decipher, decompile,
            disassemble, decrypt, or otherwise alter or interfere with (or
            attempt, encourage, or support anyone else’s attempt to engage in
            such activities) any of the software comprising or in any way making
            up a part of the Services. The use or distribution of tools designed
            for compromising security (e.g., password guessing programs,
            cracking tools or network probing tools) is strictly prohibited.
          </li>
          <li>
            You must not take any action that imposes an unreasonable or
            disproportionately large load on our network or infrastructure.
          </li>
          <li>
            You must not upload or otherwise transmit any communication,
            software, or material that contains a virus or is otherwise harmful
            to our or our users’ computers, devices or systems.
          </li>
          <li>
            You shall not engage in any other conduct that restricts or inhibits
            any person from using or enjoying the Services, or that, in our sole
            judgment, exposes us or any of our affiliates, users or any other
            third party to any liability, damages, or detriment of any type.
          </li>
        </ul>
        <p>
          Violation of any of these terms may result in the termination of your
          account. While we prohibit such conduct and content on our Services,
          you understand and agree that we cannot be responsible for content
          posted, submitted, or otherwise made available on our Services or the
          conduct of other users. You further agree that nonetheless you may be
          exposed to such materials and that you use the Services at your own
          risk.
        </p>

        <h2>Children</h2>
        <p>
          The online protection of children and young people under the age of 18
          is a shared responsibility between Kershaw Books, the parent or guardian,
          and the child. Kershaw Books aims to ensure that children and young people
          who engage with our online spaces understand the possible risks they
          face and how to minimise them. If you permit a child to use your
          device to access, listen or view Kershaw Books content, then you are solely
          responsible for deciding whether or not that Content is appropriate
          for that child to consume.
        </p>
      </Card>
    </TermsContainer>
  );
};

export default Terms;
