import React from 'react';
import { logoMonikerr } from '../../assets';
import { PoweredByMonikerrContainer } from './Style';

const PoweredByMonikerr = () => {
  return (
    <PoweredByMonikerrContainer>
      powered by{' '}
      <a href="https://monikerr.com">
        <img src={logoMonikerr} alt="Monikerr" />
      </a>
    </PoweredByMonikerrContainer>
  );
};

export default PoweredByMonikerr;
