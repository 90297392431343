import styled from '@emotion/styled';

export const ProductFaqContainer = styled.div`
  padding: 30px 20px 10px 20px;
  background: white;

  h2 {
    font-weight: bold;
    font-size: 1.8rem;
    color: #4e3866;
    margin: 0 0 20px 0;
    text-align: center;

    @media only screen and (min-width: 768px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 20px 10px 30px;
    width: 768px;
  }
`;

export const ProductFaqFooter = styled.div`
  margin: 30px 0 0 0;
  border-bottom: 3px dashed #b7b5b8;

  @media only screen and (min-width: 768px) {
    border-bottom: none;
  }
`;
