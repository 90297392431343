import styled from '@emotion/styled';

export const FeaturedReviewContainer = styled.div`
  padding: 30px 20px;
  background: white;

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }
`;
export const FeaturedReviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const FeaturedReviewTitle = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  color: #593f73;
`;
export const FeaturedReviewContent = styled.div`
  padding: 5px 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  color: #443058;
  line-height: 2.3rem;
`;
export const FeaturedReviewLink = styled.a`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.4rem;
  color: #443058;
`;
