import styled from '@emotion/styled';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  flex-grow: 1;

  h2 {
    color: #240f42;
  }

  p {
    color: #240f42;
    line-height: 2.3rem;

    a {
      text-decoration: underline;
      color: #240f42;
    }
  }
`;

export const RegisterButton = styled.a`
  display: flex;
  flex-direction: column;
  background: #4b73ca;
  border-radius: 12px;
  padding: 13px 0;
  color: white;
  text-align: center;
  margin: 20px 0;
  text-decoration: none;
`;
