import React from 'react';
import {
  OtherStoriesContainer,
  OtherStoriesTitle,
  OtherStoriesList,
  OtherStoryItem,
  OtherStoryThumb,
  OtherStoryText,
  OtherStoriesFeatureImage,
} from './Style';

import {
  storyLostToy,
  storyBirthdayCake,
  storyRainyDay,
  storySpecialDelivery,
  storyNewFriend,
  storyFruitTrap,
  storyBalloonTrick,
  kidcastKid,
  storyTreasureHunt,
} from '../../assets';

const OtherStories = () => {
  return (
    <OtherStoriesContainer>
      <OtherStoriesTitle>
        Includes 8 immersive stories personalised for your child:
      </OtherStoriesTitle>
      <OtherStoriesList>
        <OtherStoryItem>
          <OtherStoryThumb>
            <img src={storyLostToy} alt="The Lost Toy" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Lost Toy</h3>
            <p>The hunt is on to find Bozo Jones’s missing toy!</p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem>
          <OtherStoryThumb>
            <img src={storyBirthdayCake} alt="The Birthday Cake" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Birthday Cake</h3>
            <p>
              One small mistake wrecks the cake! Can you bake another one in
              time?
            </p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem>
          <OtherStoryThumb>
            <img src={storyRainyDay} alt="The The Rainy Day" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Rainy Day</h3>
            <p>Bozo Jones can’t stand the rain, but that’s about to change!</p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem>
          <OtherStoryThumb>
            <img src={storySpecialDelivery} alt="The Special Delivery" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Special Delivery</h3>
            <p>
              A package comes for Bozo Jones and you’ll never believe what’s
              inside!
            </p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem>
          <OtherStoryThumb>
            <img src={storyTreasureHunt} alt="The Treasure Hunt" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Treasure Hunt</h3>
            <p>
              The treasure is buried outside, but is it gold or something more
              precious?
            </p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem>
          <OtherStoryThumb>
            <img src={storyNewFriend} alt="The New Friend" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The New Friend</h3>
            <p>Help the lost puppy find its way back home!</p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem className="narrower">
          <OtherStoryThumb>
            <img src={storyFruitTrap} alt="The Fruit Trap" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Fruit Trap</h3>
            <p>
              It's a trap! But can Bozo Jones catch the mysterious playground
              creature?
            </p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoryItem className="narrower">
          <OtherStoryThumb>
            <img src={storyBalloonTrick} alt="The Balloon Trick" />
          </OtherStoryThumb>
          <OtherStoryText>
            <h3>The Balloon Trick</h3>
            <p>Bozo Jones huffs and puffs, but these balloons won't pop!</p>
          </OtherStoryText>
        </OtherStoryItem>
        <OtherStoriesFeatureImage>
          <img src={kidcastKid} alt="Kidcast" />
        </OtherStoriesFeatureImage>
      </OtherStoriesList>
    </OtherStoriesContainer>
  );
};

export default OtherStories;
