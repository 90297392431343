import styled from '@emotion/styled';

export const BuyButtonContainer = styled.div`
  text-align: center;
`;

export const Buy = styled.a`
  background: #d62e6f;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 1.7rem;
  text-decoration: none;
  align-items: center;
  text-align: center;
  padding: 17px 0;
  margin: 10px 0;
`;
