import styled from '@emotion/styled';
import AudioPlayer from 'react-h5-audio-player';

export const AudioPreviewContainer = styled.div`
  width: 100%;
  max-width: 768px;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
  box-shadow: none;
  padding: 0;

  &:focus {
    outline: none !important;
  }

  .rhap_progress-section {
    background: #1a0d2f;
    padding: 5px 10px 5px 20px;
  }

  .rhap_controls-section {
    margin: 0;
    background: #f7f0ff;
    justify-content: left;
  }

  .rhap_main-controls {
    display: flex;
    flex: grow;
    width: 120px;
  }

  .rhap_progress-indicator {
    background: #c7b9dd;
    height: 0px;
    width: 0px;
  }

  .rhap_time {
    color: #c7b9dd;
    font-size: 12px;
  }

  .rhap_main-controls-button {
    color: #1a0d2f;
  }
`;

export const PreviewDescription = styled.div`
  background: #f7f0ff;
  width: 100%;
  padding: 10px 20px 10px 0px;
  display: flex;
  align-items: center;

  h2 {
    font-size: 1.7rem;
    color: #1a0d2f;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #1a0d2f;
  }
`;

export const PreviewIcon = styled.div`
  img {
    width: 70px;
  }
`;

export const PreviewText = styled.div`
  padding-left: 10px;
`;
