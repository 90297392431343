import styled from '@emotion/styled';

export const GiftDetails = styled.div``;

export const GiftSelection = styled.div`
  h3 {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold
    font-size: 1.6rem;
    color: #5a2b51;
    line-height: 2.3rem;
    margin: 30px 0 20px 0;
  }

  h2 {
    margin-bottom: 20px;
  }
`;
