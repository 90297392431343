import { css } from '@emotion/react';

export const base = css`
  h1,
  h2,
  h3 {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    color: #66528c;
  }

  h1 {
    font-size: 22px;
    margin: 10px 0 20px 0;
  }

  h2 {
    font-size: 17px;
    margin: 10px 0;
  }

  p {
    font-family: 'Quicksand', sans-serif;
    color: #1e2833;
    margin: 10px 0;
    line-height: 22px;

    a {
      color: #66528c;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  strong {
    font-family: 'Quicksand', sans-serif;
  }

  ul {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    margin: 10px 0 10px 20px;
    list-style: disc;

    li {
      margin: 0 0 10px 0;
      color: #1e2833;
      font-family: 'Quicksand', sans-serif;
      font-weight: 400;
      line-height: 22px;
    }
  }
`;
