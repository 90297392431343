import styled from '@emotion/styled';

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  flex-grow: 1;

  h2 {
    color: #240f42;
  }

  p {
    line-height: 2.3rem;
    color: #240f42;

    a {
      font-family: 'Quicksand', sans-serif;
      text-decoration: underline;
      color: #240f42;
    }
  }
`;
