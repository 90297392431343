import styled from '@emotion/styled';

export const FooterContainer = styled.div`
  width: 100%;
  background: #201237;
  height: 55px;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const FooterPageLinks = styled.div`
  display: flex;
`;

export const FooterLink = styled.a`
  padding: 0 5px;
  color: #ab95cf;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  &:hover {
    color: white;
  }
`;
