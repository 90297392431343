import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Rating from '../Rating';
import {
  TestimonialsContainer,
  TestimonialsHeader,
  Testimonial,
  Quote,
  Source,
  QuoteWrapper,
  RatingContainer,
} from './Style';

const Testimonials = () => {
  const testimonialsData = [
    {
      quote:
        'Best gift for my three-year old nephew! We played them straight away and he loved it.',
      source: 'Kelly 😊',
    },
    {
      quote:
        'Stories are so well done! Max loves listening to them in the car.',
      source: 'Dan ☺️',
    },
    {
      quote: 'Easy to purchase, stories played instantly and sound fantastic.',
      source: 'Ophelia 🤩',
    },
    {
      quote:
        'Exactly what I was looking for. Gift certificate was perfect and she loves the stories.',
      source: 'Jeremy 😳',
    },
    {
      quote:
        'Second purchase. Easy switching between different stories for each of our kids.',
      source: 'Caroline 🐶',
    },
  ];
  return (
    <TestimonialsContainer>
      <TestimonialsHeader>What parents are saying:</TestimonialsHeader>
      <Carousel showArrows={false} showThumbs={false} showStatus={false}>
        {testimonialsData.map(function (testimonial, index) {
          return (
            <Testimonial key={'testimonial-' + index}>
              <QuoteWrapper>
                <RatingContainer>
                  <Rating stars={5} />
                </RatingContainer>
                <Quote>{testimonial.quote}</Quote>
                <Source>{testimonial.source}</Source>
              </QuoteWrapper>
            </Testimonial>
          );
        })}
      </Carousel>
    </TestimonialsContainer>
  );
};

export default Testimonials;
