import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { Button, Rating } from '../../components';
import {
  shopBannerImage1,
  shopBannerImage2,
  shopBannerImage3,
  shopBannerImage4,
  shopBannerImage5,
} from '../../assets';

import {
  CollectionDetailsContainer,
  CollectionHeader,
  CollectionTitle,
  CollectionDescription,
  Price,
  GiftCTA,
  GiftLink,
} from './Style';

const CollectionDetails = () => {
  const navigate = useNavigate();

  const shopBannerData = [
    {
      image: shopBannerImage1,
      alt: 'Kidcast Audio Storybook - library',
    },
    {
      image: shopBannerImage2,
      alt: 'Kidcast Audio Storybook - Profile',
    },
    {
      image: shopBannerImage3,
      alt: 'Kidcast Audio Storybook - playlist',
    },
    {
      image: shopBannerImage4,
      alt: 'Kidcast Audio Storybook - stories',
    },
    {
      image: shopBannerImage5,
      alt: 'Kidcast Audio Storybook - stories',
    },
  ];
  return (
    <CollectionDetailsContainer>
      <CollectionHeader>
        <CollectionTitle>
          <h2>Kidcast Audio Storybook</h2>
          <h3>8 x immersive stories for your child</h3>
        </CollectionTitle>
        <Rating stars={5} />
      </CollectionHeader>
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
      >
        {shopBannerData.map(function (image, index) {
          return (
            <img src={image.image} alt={image.alt} key={'image-' + index} />
          );
        })}
      </Carousel>
      <CollectionDescription>
        <p>
          Kidcast Personalised Audio Storybook casts your child in their very
          own audio adventures. Just wait for the look on their face when they
          hear their name!
        </p>
        <ul>
          <li>✅ Recommended for ages 2-6 years</li>
          <li>⭐️ Includes your child's name in every story</li>
          <li>▶️ Instantly available to play from any device</li>
          <li>🇦🇺 Approved by Australian educators</li>
        </ul>
        <Price>
          $18.99 AUD <span>Sale</span> <strike>$51.99 AUD</strike>
          <p>Listen to their first story FREE.</p>
        </Price>
        <Button
          variation="primary"
          onClick={() => {
            window.gtag('event', 'click_personalise', {
              event_category: 'shop',
              event_label: 'Personalise Stories',
            });
            window.fbq('track', 'CustomizeProduct');
            navigate(`/personalise`);
          }}
        >
          Add Child’s Name
        </Button>
        <GiftCTA>
          <span>or</span>
          <GiftLink to="/gift-purchase">Purchase as gift</GiftLink>
        </GiftCTA>
      </CollectionDescription>
    </CollectionDetailsContainer>
  );
};

export default CollectionDetails;
