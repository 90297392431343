import React, { useEffect, useState } from 'react';
import {
  ShareContainer,
  ShareContainerInner,
  ShareContainerContent,
  ShareContainerImage,
  ShareFromContainer,
  ShareTextContainer,
  ShareTitle,
} from './Style';

import { emailIsValid } from '../../lib/utils';
import { Button, TextField } from '../';
import { useUserContext } from '../../lib/useUser';
import { desktopSubscribeImage } from '../../assets';

const Subscribe = ({ selectedName }) => {
  //whether the email is valid for subscribing
  const [subscribeEmail, setSubscribeEmail] = useState('');
  const [subscribeEmailClass, setSubscribeEmailClass] = useState('');
  const [subscribeFormClass, setSubscribeFormClass] = useState('');

  const { userSubscribed, setUserSubscribed } = useUserContext();

  //subscribe func
  const subscribe = async () => {
    if (
      subscribeEmail &&
      subscribeEmail !== '' &&
      emailIsValid(subscribeEmail)
    ) {
      const data = {
        email: subscribeEmail,
        story_name: selectedName,
      };

      await fetch('/.netlify/functions/subscribe', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      setSubscribeEmailClass('');
      setSubscribeFormClass('hidden');
      localStorage.setItem('subscribed', 'true');
      setUserSubscribed('true');

      window.gtag('event', 'subscribe_from_home', {
        event_category: 'subscribe',
        event_label: 'subscribe',
      });
    }
  };

  //when the subscribe email is changed
  useEffect(() => {
    setSubscribeEmail(localStorage.getItem('subscribeEmail'));

    if (emailIsValid(subscribeEmail)) {
      setSubscribeEmailClass('complete');
    } else {
      setSubscribeEmailClass('');
    }
  }, [subscribeEmail]);

  //if subscribed
  useEffect(() => {
    if (userSubscribed === 'true') {
      setSubscribeFormClass('hidden');
    } else {
      setSubscribeFormClass('');
    }
  }, [userSubscribed]);

  return (
    <ShareContainer>
      <ShareTitle>Want to make your child smile?</ShareTitle>
      <ShareContainerInner>
        <ShareContainerContent>
          <ShareTextContainer>
            <p>
              Play them their first Kidcast story and watch their face light up
              when they hear their name and imagine themselves in the story. Try
              playing them:
            </p>
            <p>🛌 At bedtime</p>
            <p>🚗 In the car</p>
            <p>📚 During storytime</p>
            <p>📺 Instead of screentime</p>
          </ShareTextContainer>
          {subscribeFormClass === '' ? (
            <ShareTextContainer>
              <p>
                Sign up for free gifts, early access to new stories and special
                offers:
              </p>
            </ShareTextContainer>
          ) : (
            <ShareTextContainer>
              <p>Thank you for subscribing!</p>
            </ShareTextContainer>
          )}
          <ShareFromContainer className={subscribeFormClass}>
            <TextField
              id="subscribe-email-input"
              placeholder="Your email address"
              value={subscribeEmail || ''}
              onChange={(event) => {
                setSubscribeEmail(event.target.value);
                localStorage.setItem('subscribeEmail', event.target.value);
              }}
              style={{ margin: '0 0 26px 0' }}
              className={subscribeEmailClass}
            />
            <Button
              variation="secondary"
              variant="contained"
              onClick={subscribe}
            >
              Sign Up
            </Button>
          </ShareFromContainer>
        </ShareContainerContent>
        <ShareContainerImage>
          <img src={desktopSubscribeImage} alt="Subscribe" />
        </ShareContainerImage>
      </ShareContainerInner>
    </ShareContainer>
  );
};

export default Subscribe;
