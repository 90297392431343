import styled from '@emotion/styled';
import AudioPlayer from 'react-h5-audio-player';

export const AudioPlayerContainer = styled.div`
  width: 100%;
  max-width: 768px;

  ${(props) => {
    if (props.fixedPosition === true) {
      return `
        position: fixed;
        bottom: 0;
        z-index: 9;
    `;
    }
  }}
`;

export const StyledAudioPlayer = styled(AudioPlayer)`
  box-shadow: none;
  padding: 0;
  background: #f4ecff;

  &:focus {
    outline: none !important;
  }

  .rhap_progress-section {
    background: #1a0d2f;
    padding: 5px 10px 5px 20px;
  }

  .rhap_controls-section {
    margin: 0;
    background: #f7f0ff;
    justify-content: left;
  }

  .rhap_main-controls {
    display: flex;
    flex: grow;
    width: 120px;
  }

  .rhap_progress-indicator {
    background: #c7b9dd;
    height: 0px;
    width: 0px;
  }

  .rhap_time {
    color: #c7b9dd;
    font-size: 12px;
  }

  .rhap_main-controls-button {
    color: #1a0d2f;
  }

  ${(props) => {
    if (props.fullWidth === false) {
      return `
        padding: 0 20px 20px 20px;
    `;
    }
  }}
`;

export const ShareTextLink = styled.a`
  color: white;
  text-decoration: none;
  height: 38px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  z-index: 9;
  text-align: center;

  svg {
    margin-left: 10px;
  }
`;

export const PlayerDescription = styled.div`
  background: #fff9f4;
  width: 100%;
  padding: 10px 20px 10px 15px;
  display: flex;
  align-items: center;

  h2 {
    font-size: 1.7rem;
    color: #1a0d2f;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #1a0d2f;
  }
`;

export const PlayerIcon = styled.div`
  img {
    width: 70px;
  }
`;

export const PlayerText = styled.div`
  padding-left: 10px;
  position: relative;
  width: 100%;

  h2 {
    padding-right: 20px;
  }
`;

export const PlayerDetailLink = styled.a`
  position: absolute;
  top: 7px;
  right: 0;
`;

export const StoryInfoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  background: #fff9f4;
  boxshadow: 24;
  padding: 20px;
  border-radius: 7px;
`;

export const FreeUserUpsell = styled.div`
  background: #fff;
  padding: 15px 20px;
  width: 100%;
  text-align: center;

  img {
    width: 100%;
    margin: 15px 0 10px 0;
  }
`;
