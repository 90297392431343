import React from 'react';
import { capitaliseName } from '../../lib/utils';
import { WhenToPlayContainer, Example } from './Style';

const WhenToPlay = ({ selectedName }) => {
  return (
    <WhenToPlayContainer>
      <h2>When should I play {capitaliseName(selectedName)} their stories?</h2>
      <Example>
        <h3>🛌 Bedtime / Storytime</h3>
        <p>
          Kidcast stories work well at any point of the bedtime routine and can
          also work as a substitute for reading if you need a night off the
          books. Get them into bed, put on a story and let them drift off with
          you in the other room.
        </p>
      </Example>
      <Example>
        <h3>🚗 In The Car</h3>
        <p>
          Whether it’s a short drive or a long road trip, Kidcast helps you get
          through it without interruption and sparks some interesting
          conversation as you listen along together.
        </p>
      </Example>
      <Example>
        <h3>💻 Screentime</h3>
        <p>
          Kidcast is the healthy alternative to screentime and perfect for
          filling those gaps where you need to keep them occupied, but don’t
          want them to get settled-in for a movie or multiple episodes.
        </p>
      </Example>
    </WhenToPlayContainer>
  );
};

export default WhenToPlay;
