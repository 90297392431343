import React from 'react';
import { FormTextContainer } from './Style';

const FormText = ({ children, ...rest }) => {
  return (
    <FormTextContainer {...rest}>
      {children}
    </FormTextContainer>
  );
};

export default FormText;
