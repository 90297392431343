import React from 'react';
import { BenefitsContainer, Benefit } from './Style';

const BenefitsParents = ({ title }) => {
  return (
    <BenefitsContainer>
      <h2>{title}</h2>
      <Benefit>
        <h3>🎨 Creative Kids</h3>
        <p>
          Fostering creativity and imagination in young children has been linked
          to academic success. Help your child unlock their imagination and get
          ready to watch their creativity and intellectual curiosity blossom.
        </p>
      </Benefit>
      <Benefit>
        <h3>🍎 Healthier Entertainment</h3>
        <p>
          Managing screentime is a serious challenge for most parents because
          there aren’t great alternatives. Kidcast is a guilt-free entertainment
          option, capturing your child’s attention just as effectively as
          screens, but building a love of learning as they listen along.
        </p>
      </Benefit>
      <Benefit>
        <h3>😇 Better Behaviour</h3>
        <p>
          Because they place your child into their own adventure, Kidcast
          stories help develop confidence and reinforce positive behaviours so
          they are more independent, better with other children, less needy, and
          solve problems more effectively.
        </p>
      </Benefit>
    </BenefitsContainer>
  );
};

export default BenefitsParents;
