import {
  storyLostToy,
  storyBirthdayCake,
  storyRainyDay,
  storySpecialDelivery,
  storyTreasureHunt,
  storyNewFriend,
  storyFruitTrap,
  storyBalloonTrick,
} from '../assets';

export const stories = [
  {
    id: 'the-treasure-hunt',
    title: 'The Treasure Hunt',
    description:
      'The treasure is buried outside, but is it gold or something even more precious?',
    thumb: storyTreasureHunt,
    credits: (
      <p>
        Production by: Adam Kershaw <br />
        Opening Theme by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-birthday-cake',
    title: 'The Birthday Cake',
    description:
      'One small mistake wrecks the cake! Can you bake another one in time?',
    thumb: storyBirthdayCake,
    credits: (
      <p>
        Production by: Adam Kershaw & James Milsom <br />
        Original Music by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-special-delivery',
    title: 'The Special Delivery',
    description:
      'A package comes for Bozo Jones and you’ll never believe what’s inside!',
    thumb: storySpecialDelivery,
    credits: (
      <p>
        Production by: Adam Kershaw & James Milsom <br />
        Original Music by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-lost-toy',
    title: 'The Lost Toy',
    description: 'The hunt is on to find Bozo Jones’s missing toy! ',
    thumb: storyLostToy,
    credits: (
      <p>
        Production by: Adam Kershaw & James Milsom <br />
        Original Music by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-rainy-day',
    title: 'The Rainy Day',
    description: 'Bozo Jones can’t stand the rain, but that’s about to change!',
    thumb: storyRainyDay,
    credits: (
      <p>
        Production by: Adam Kershaw & James Milsom <br />
        Original Music by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-new-friend',
    title: 'The New Friend',
    description: 'Help the lost puppy find its way back home!',
    thumb: storyNewFriend,
    credits: (
      <p>
        Production by: Adam Kershaw <br />
        Opening Theme by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-fruit-trap',
    title: 'The Fruit Trap',
    description:
      'It’s a trap! But can Bozo Jones catch the mysterious playground creature?',
    thumb: storyFruitTrap,
    credits: (
      <p>
        Production by: Adam Kershaw <br />
        Opening Theme by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
  {
    id: 'the-balloon-trick',
    title: 'The Balloon Trick',
    description: "Bozo Jones huffs and puffs, but these balloons won't pop!",
    thumb: storyBalloonTrick,
    credits: (
      <p>
        Production by: Adam Kershaw <br />
        Opening Theme by: James Milsom <br />
        Narrated by: Adam Kershaw <br />
        Copyright Adam Kershaw 2022
      </p>
    ),
  },
];

export const getStoryData = (storyId) => {
  let foundStory = false;

  stories.every((story) => {
    if (storyId === story.id) {
      foundStory = story;
      return false;
    } else {
      return true;
    }
  });

  return foundStory;
};
