import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { jsPDF } from 'jspdf';

import { Footer, PlainContainer, PoweredByMonikerr } from '../../components';

const GiftDownload = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [userGiftCardPurchases, setUserGiftCardPurchases] = useState();
  const [purchasesLoading, setPurchasesLoading] = useState(false);
  const [giftCardLinks, setGiftCardLinks] = useState();
  const queryStringCode = searchParams.get('code');

  const downloadGiftCard = (giftCard) => {
    const redeemLinkQR = `${process.env.REACT_APP_PUBLIC_URL}/gift-redeem?code=${giftCard.stripe_id}%26purchase=${giftCard.purchase_record}%26record=${giftCard.id}`;
    const redeemLink = `${process.env.REACT_APP_PUBLIC_URL}/gift-redeem?code=${giftCard.stripe_id}&purchase=${giftCard.purchase_record}&record=${giftCard.id}`;

    const doc = new jsPDF();
    doc.addImage(
      `${process.env.REACT_APP_PUBLIC_URL}/giftcard-bg.png`,
      'JPEG',
      0,
      0,
      210,
      297
    );
    let imageData = new Image(300, 300);
    imageData.src = `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${redeemLinkQR}&choe=UTF-8`;
    doc.addImage(imageData, 'PNG', 60, 144, 90, 90);
    doc.link(60, 144, 90, 90, { url: redeemLink });
    doc.save(
      `kidcast_giftcard_${giftCard.stripe_id}_${giftCard.purchase_record}.pdf`
    );
  };

  // grab gift card data on load
  useEffect(() => {
    if (userGiftCardPurchases) {
      let giftCardList = [];
      // we already have the data we need
      console.log('gift certificate purchases retrieved');

      if (userGiftCardPurchases.length > 0) {
        userGiftCardPurchases.forEach((giftCard, index) => {
          giftCardList.push(
            <li key={`giftcard-${index + 1}`}>
              <a
                href="#download"
                onClick={() => {
                  downloadGiftCard(giftCard);
                }}
              >
                Download Gift Certificate {index + 1}
              </a>
            </li>
          );
        });
      } else {
        giftCardList.push(
          <li key="no-gift-cards">
            Could not retrieve gift certificates, please check the link and try
            again. If you are having issues email us at{' '}
            <a href="mailto: hello@kidcast.fm">hello@kidcast.fm</a>
          </li>
        );
      }

      setGiftCardLinks(giftCardList);
    } else {
      if (queryStringCode) {
        // else fetch from functions
        console.log('fetching...');
        setPurchasesLoading(true);

        const fetchPurchases = async () => {
          const data = {
            stripe_id: queryStringCode,
          };

          const fetchFunction = await fetch(
            '/.netlify/functions/gift-purchases',
            {
              method: 'POST',
              headers: {
                'Content-type': 'application/json',
              },
              body: JSON.stringify(data),
            }
          );

          const fetchResponse = await fetchFunction;

          return fetchResponse.json();
        };

        fetchPurchases().then((res) => {
          setPurchasesLoading(false);
          setUserGiftCardPurchases(res);
        });
      } else {
        navigate(`/`);
      }
    }
  }, [
    navigate,
    queryStringCode,
    userGiftCardPurchases,
    setUserGiftCardPurchases,
  ]);
  return (
    <>
      <Helmet>
        <title>Gift Certificate Download | Kidcast</title>
      </Helmet>
      <PlainContainer>
        <h2>Gift Certificate Download</h2>
        <p>
          Thank you for your gift certificate purchase of the Kidcast Starter
          Pack
        </p>
        <p>
          This collection includes eight personalised audio stories and will be
          available instantly to play from any device upon redemption.
        </p>
        <p>
          Click below to download and open your gift certificate(s) then simply
          print or forward on to the recipient(s).
        </p>
        {purchasesLoading ? (
          <p>Fetching gift certificates...</p>
        ) : (
          <ul>{giftCardLinks}</ul>
        )}
      </PlainContainer>
      <Footer />
      <PoweredByMonikerr />
    </>
  );
};

export default GiftDownload;
