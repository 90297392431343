import React from 'react';

import { homeTaglineImage1, desktopTaglineImage } from '../../assets';

import {
  FeatureImageAndTextContainer,
  FeatureImage,
  FeatureText,
  ImageSmall,
  ImageLarge,
} from './Style';

const FeatureImageAndText = () => {
  return (
    <FeatureImageAndTextContainer>
      <FeatureImage>
        <ImageSmall>
          <img
            src={homeTaglineImage1}
            alt="Play stories with your child as the star"
          />
        </ImageSmall>
        <ImageLarge>
          <img
            src={desktopTaglineImage}
            alt="Play stories with your child as the star"
          />
        </ImageLarge>
      </FeatureImage>
      <FeatureText>
        <h2>Play stories with your child as the star 💫</h2>
        <p>For Ages 2-6.</p>
      </FeatureText>
    </FeatureImageAndTextContainer>
  );
};

export default FeatureImageAndText;
