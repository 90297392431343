import styled from '@emotion/styled';

export const ListenNowButtonContainer = styled.div`
  width: 100%;
  padding: 0px 40px 20px 40px;
  margin-bottom: 5px;

  @media only screen and (min-width: 768px) {
    width: 768px;
  }
`;

export const OrContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: white;
`;

export const SaveStoryContainer = styled.div`
  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: white;
    text-align: left;
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 2.4rem;

    p {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }
`;

export const LoadingWithSpacer = styled.div`
  margin-bottom: 20px;
`;
