import styled from '@emotion/styled';

const StyledMainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export { StyledMainContainer };
