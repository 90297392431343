import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormText, TextField, Button } from '../';

import { emailIsValid } from '../../lib/utils';

import CircularProgress from '@mui/material/CircularProgress';

import {
  ListenNowButtonContainer,
  SaveStoryContainer,
  OrContainer,
  LoadingWithSpacer,
} from './Style';

const ListenNowButton = ({ selectedName }) => {
  const navigate = useNavigate();

  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredEmailClass, setEnteredEmailClass] = useState('');
  const [saveLoading, setSaveLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  // generated story email send
  const sendGeneratedStoryDetails = async () => {
    const data = {
      generated_name: selectedName,
    };
    const response = await fetch('/.netlify/functions/generate-story', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    console.log(response);
  };

  // save story (send email + subscribe)
  const saveFreeStory = async () => {
    console.log('Saving subscriber and sending email with link');

    if (enteredEmail && enteredEmail !== '' && emailIsValid(enteredEmail)) {
      const subscribe = async () => {
        const data = {
          email: enteredEmail,
          selected_name: selectedName,
        };

        await fetch('/.netlify/functions/subscribe', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        });
      };

      subscribe().then((res) => {
        setSaveLoading(false);
        setSaved(true);
      });
    }
  };

  // when the email is changed
  useEffect(() => {
    setEnteredEmail(localStorage.getItem('enteredEmail'));

    if (emailIsValid(enteredEmail)) {
      setEnteredEmailClass('complete');
    } else {
      setEnteredEmailClass('');
    }
  }, [enteredEmail]);

  return (
    <ListenNowButtonContainer>
      <SaveStoryContainer>
        {saved ? (
          <>
            <FormText>
              We've just sent you a link to {selectedName}'s free story. You can
              also listen now using the link below.
            </FormText>
          </>
        ) : (
          <>
            <FormText>
              Enter your email below and we'll send you a link to {selectedName}
              's story so you can listen anywhere or anytime!
            </FormText>
            <TextField
              id="notification-input"
              name="notificaiton-input"
              placeholder="Enter your email"
              value={enteredEmail || ''}
              onChange={(event) => {
                setEnteredEmail(event.target.value);
                localStorage.setItem('enteredEmail', event.target.value);
              }}
              className={enteredEmailClass}
            />
            {saveLoading ? (
              <LoadingWithSpacer>
                <CircularProgress color="secondary" />
              </LoadingWithSpacer>
            ) : (
              <>
                <Button
                  variation="tertiary"
                  variant="contained"
                  onClick={() => {
                    setSaveLoading(true);
                    window.gtag('event', 'click_save_for_later', {
                      event_category: 'home_screen',
                      event_label: 'save_story',
                    });
                    saveFreeStory();
                  }}
                >
                  Email {selectedName}'s free story
                </Button>
                <OrContainer>or</OrContainer>
              </>
            )}
          </>
        )}
      </SaveStoryContainer>
      <Button
        variation="secondary"
        variant="contained"
        onClick={() => {
          window.gtag('event', 'click', {
            event_category: 'Step2',
            event_label: 'generate_story',
          });
          sendGeneratedStoryDetails();
          navigate(`play?selected_name=${selectedName}`);
        }}
      >
        Listen Now
      </Button>
    </ListenNowButtonContainer>
  );
};

export default ListenNowButton;
