import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const CollectionDetailsContainer = styled.div`
  background: #fffdfc;

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }
`;

export const CollectionHeader = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 420px) {
    flex-direction: row;
    padding-bottom: 30px;
  }
`;

export const CollectionTitle = styled.div`
  h2 {
    font-size: 2rem;
    color: #4f2747;
  }

  h3 {
    font-size: 1.6rem;
    color: #241539;
  }
`;

export const CollectionDescription = styled.div`
  padding: 20px;

  p {
    font-size: 1.5rem;
    line-height: 2.3rem;
    color: #241539;
    margin-bottom: 20px;

    @media only screen and (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  ul {
    list-style: none;
    margin: 10px 0 15px 3px;
    padding: 0;

    li {
      font-size: 1.5rem;
      color: #241539;

      @media only screen and (min-width: 768px) {
        font-size: 1.6rem;
      }
    }
  }
`;

export const Price = styled.div`
  margin: 25px 0 35px 0;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.8rem;
  color: #4f2747;

  span {
    color: #c72664;
  }

  strike {
    font-weight: 400;
    color: #999;
  }

  p {
    font-weight: 400;
  }

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const GiftCTA = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  span {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.6rem;
    color: #5a2b51;
  }
`;

export const GiftLink = styled(Link)`
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #c72664;
  text-align: center;
  padding: 15px 0 0 0;
`;
