import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';

export const PersonalisationHeader = styled.div`
  margin-bottom: 20px;
`;

export const PersonalisationForm = styled.div`
  margin-bottom: 40px;

  h3 {
    margin: 0 0 20px 0;
  }

  .MuiTextField-root {
    margin-bottom: 0;
  }

  .MuiAutocomplete-popper {
    .MuiPaper-elevation {
      background: #fffdf8;
      border-radius: 12px;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      color: #cd6d16;
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;

  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;

export const PreviewAndPurchase = styled.div`
  margin-bottom: 15px;
`;

export const PreviewExtraInfo = styled.div``;
