import React from 'react';
import { SocialLinks, Footer, PoweredByMonikerr } from '../../components';
import { ConfirmationContainer } from './Style';

const GiftConfirmation = () => {
  return (
    <>
      <ConfirmationContainer>
        <h2>Thank you!</h2>
        <p>Your gift certificate purchase was successful.</p>
        <p>
          Your purchase receipt and gift certificates have been emailed to you.
          To download or print your gift certificates, simply follow the link in
          the email.
        </p>
        <p>
          Didn’t receive the email? Please check your Promotions inbox then
          email <a href="mailto: hello@kidcast.fm">hello@kidcast.fm</a> and
          we’ll get right on it.
        </p>
        <SocialLinks />
      </ConfirmationContainer>
      <Footer />
      <PoweredByMonikerr />
    </>
  );
};

export default GiftConfirmation;
