import React, { useEffect, useState } from 'react';
import 'react-h5-audio-player/lib/styles.css';
import Modal from '@mui/material/Modal';

import { useUserContext } from '../../lib/useUser';
import { getStoryData } from '../../lib/stories';

import { ReactComponent as IconInfo } from '../../assets/icon-info.svg';

import {
  AudioPlayerContainer,
  StyledAudioPlayer,
  PlayerDescription,
  PlayerIcon,
  PlayerText,
  PlayerDetailLink,
  StoryInfoContainer,
} from './Style';

const AudioPlayer = ({
  selectedAudio,
  selectedName,
  selectedStory,
  onEndedCallback,
  fixedPosition,
}) => {
  const { machineId } = useUserContext();

  const [storyTitle, setStoryTitle] = useState('');
  const [storyDescription, setStoryDescription] = useState('');
  const [storyThumb, setStoryThumb] = useState('');
  const [storyCredits, setStoryCredits] = useState('');

  // modal for additional player info
  const [openPlayerInfo, setOpenPlayerInfo] = React.useState(false);
  const handleOpenPlayerInfo = () => setOpenPlayerInfo(true);
  const handleClosePlayerInfo = () => setOpenPlayerInfo(false);

  // play tracking
  const sendPlayNotification = async () => {
    window.gtag('event', 'play_audio_' + selectedStory.replaceAll('-', '_'), {
      event_category: 'audio_player',
      event_label: selectedName,
    });

    const data = {
      played_for: selectedName,
      story: selectedStory,
      email: localStorage.getItem('enteredEmail') || '',
      machineId: machineId,
    };

    const response = await fetch('/.netlify/functions/play-story', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    console.log(response);
  };

  // grab related story details on story passed in
  useEffect(() => {
    if (selectedStory) {
      let selectedStoryData = getStoryData(selectedStory);
      setStoryTitle(selectedStoryData.title);
      setStoryDescription(selectedStoryData.description);
      setStoryThumb(selectedStoryData.thumb);
      setStoryCredits(selectedStoryData.credits);
    }
  }, [selectedStory]);

  return (
    <AudioPlayerContainer fixedPosition={fixedPosition}>
      <Modal
        open={openPlayerInfo}
        onClose={handleClosePlayerInfo}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StoryInfoContainer>
          <h2>{storyTitle}</h2>
          <p>{storyDescription}</p>
          <h3>Credits</h3>
          <p>{storyCredits}</p>
        </StoryInfoContainer>
      </Modal>

      <StyledAudioPlayer
        // autoPlay
        fullWidth={fixedPosition}
        layout="stacked-reverse"
        listenInterval={10000}
        src={selectedAudio}
        onPlay={(e) => sendPlayNotification()}
        onPause={(e) =>
          window.gtag(
            'event',
            'pause_audio_' + selectedStory.replaceAll('-', '_'),
            {
              event_category: 'audio_player',
              event_label: Math.floor(e.target.currentTime),
            }
          )
        }
        onEnded={onEndedCallback}
        onSeeking={(e) =>
          window.gtag(
            'event',
            'seek_audio_' + selectedStory.replaceAll('-', '_'),
            {
              event_category: 'audio_player',
              event_label: Math.floor(e.target.currentTime),
            }
          )
        }
        showJumpControls={false}
        customVolumeControls={[]}
        customControlsSection={[
          'MAIN_CONTROLS',
          <PlayerDescription>
            <PlayerIcon>
              <img src={storyThumb} alt={storyTitle} />
            </PlayerIcon>
            <PlayerText>
              <PlayerDetailLink onClick={handleOpenPlayerInfo}>
                <IconInfo />
              </PlayerDetailLink>
              <h2>{storyTitle}</h2>
              <p>{storyDescription}</p>
            </PlayerText>
          </PlayerDescription>,
        ]}
        customProgressBarSection={['CURRENT_TIME', 'PROGRESS_BAR']}
      />
    </AudioPlayerContainer>
  );
};

export default AudioPlayer;
