import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import { NotifyContainer } from './Style';

import { emailIsValid } from '../../lib/utils';
import { FormText, TextField, Button } from '../';

const Notify = ({ notifyContainerClass, enteredName }) => {
  const [enteredEmail, setEnteredEmail] = useState('');
  const [enteredEmailClass, setEnteredEmailClass] = useState('');
  const [notifyFormVisible, setNotifyFormVisible] = useState(true);
  const [notifyLoading, setNotifyLoading] = useState(false);

  //notification email send
  const sendNotificationRequest = async () => {
    console.log('Sending notification request');

    const data = {
      email: enteredEmail,
      requested_name: enteredName,
    };

    if (enteredEmail && enteredEmail !== '' && emailIsValid(enteredEmail)) {
      const response = await fetch('/.netlify/functions/request-story', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      console.log(response);

      setNotifyFormVisible(false);
      setNotifyLoading(false);
    }
  };

  // when the notification email is changed
  useEffect(() => {
    setEnteredEmail(localStorage.getItem('enteredEmail'));

    if (emailIsValid(enteredEmail)) {
      setEnteredEmailClass('complete');
    } else {
      setEnteredEmailClass('');
    }
  }, [enteredEmail]);

  return (
    <NotifyContainer className={notifyContainerClass}>
      {notifyFormVisible ? (
        <>
          <FormText>
            <p>
              We’re sorry, the stories for <strong>{enteredName}</strong> aren’t
              ready yet so we’re getting right on it.
            </p>
            <p>
              <strong>Give us 24 hours and we’ll notify you by email.</strong>
            </p>
          </FormText>
          <TextField
            id="notification-input"
            name="notificaiton-input"
            placeholder="Enter your email"
            value={enteredEmail || ''}
            onChange={(event) => {
              setEnteredEmail(event.target.value);
              localStorage.setItem('enteredEmail', event.target.value);
            }}
            className={enteredEmailClass}
          />
          {notifyLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              variation="primary"
              variant="contained"
              onClick={() => {
                setNotifyLoading(true);
                window.gtag('event', 'click_send_notification', {
                  event_category: 'landing_screen',
                  event_label: 'notification_request',
                });
                sendNotificationRequest();
              }}
            >
              Notify Me
            </Button>
          )}
        </>
      ) : (
        <FormText>
          <p>
            Thanks! We'll send you an email once the story for {enteredName} is
            ready!
          </p>
        </FormText>
      )}
    </NotifyContainer>
  );
};

export default Notify;
