import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';

export const RedemptionDetails = styled.div``;

export const RedemptionForm = styled.div`
  .MuiTextField-root {
    margin-bottom: 0;
  }

  .MuiAutocomplete-popper {
    .MuiPaper-elevation {
      background: #fffdf8;
      border-radius: 12px;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      color: #cd6d16;
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    }
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;

  .MuiAutocomplete-endAdornment {
    display: none;
  }
`;

export const ButtonContainer = styled.div`
  margin: 30px 0;
  text-align: center;
`;

export const ErrorContainer = styled.div`
  margin: 30px 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: bold;
  color: #c00000;
`;
