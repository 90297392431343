import styled from '@emotion/styled/macro';

export const PlaylistContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ListTitle = styled.div`
  margin-left: 15px;
  font-size: 1.7rem;
  color: white;
  font-weight: 500;
  font-family: 'Quicksand', sans-serif;
  width: 100%;
  text-align: left;
`;

export const ListItem = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(216, 216, 216, 0.15);
  margin-bottom: 2px;
  padding: 0 40px;
  text-decoration: none;
  cursor: pointer;

  &.active {
    background: #ffffff;

    ${ListTitle} {
      color: #5d4864;
    }
  }

  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
`;

export const ListThumb = styled.div`
  width: 75px;
  text-align: center;

  img {
    height: 55px;
  }
`;

export const ListNote = styled.span`
  font-size: 1.2rem;
  color: #ccc;
`;

export const ListLocked = styled.img`
  text-align: right;
`;

export const Collection = styled.div`
  background: #5f3492;
`;

export const CollectionTitle = styled.div`
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  color: #ffffff;
  text-align: center;
  padding: 20px 0;
`;

export const CollectionList = styled.div``;
