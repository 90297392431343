import React from 'react';

import { Card } from '../../components';
import { Helmet } from 'react-helmet-async';
import { AboutContainer } from './Style';

const About = () => {
  window.scroll(0, 0);
  return (
    <AboutContainer>
      <Helmet>
        <title>Our Story | Kidcast</title>
      </Helmet>
      <Card>
        <h1>Our Story</h1>
        <p>
          Kidcast.fm was founded by best-selling children’s author, Adam
          Kershaw.
        </p>
        <p>
          Adam is a father to three young kids in Melbourne, Australia. 
          He created Kidcast to make it easier for parents to stimulate 
          their child’s creativity and set them on the path to becoming 
          successful learners from an early age.
        </p>
        <p>
          Every Kidcast.fm adventure is an immersive experience, weaving the
          child’s name into the story as the main character to bring their
          imagination to life.
        </p>
        <p>
          The audio stories have been carefully crafted to stimulate cognitive
          and language development, enhance creativity and improve the emotional
          wellbeing of young children in-line with Australia’s
          nationally-approved learning frameworks.
        </p>
        <p>
          Kidcast.fm launched as an invitation-only platform in September 2022,
          with hundreds of parents and educators embracing the innovative
          approach to storytime, including private schools and early childhood
          centres across Australia.
        </p>
        <p>
          Kidcast.fm has also partnered with Plaza Premium Group, the world’s
          largest global airport lounge network, to give traveling families a
          fun and educational entertainment option in transit.
        </p>
        <p>
          You can email Kidcast about partnership opportunities, media enquiries
          or with general feedback and suggestions.
        </p>
        <p>
          <a href="mailto:hello@kidcast.fm">hello@kidcast.fm</a>
        </p>
      </Card>
    </AboutContainer>
  );
};

export default About;
