import styled from '@emotion/styled';

export const TestimonialsContainer = styled.div`
  width: 100%;
  background: #ecdeff;
  padding: 30px 0 20px 0;

  .slider-wrapper {
    height: 200px;
  }

  .control-dots {
    .dot {
      background: #563f7d;
    }
  }

  @media only screen and (min-width: 768px) {
    width: 768px;
    border-radius: 15px;
    margin-bottom: 30px;
    padding: 40px 0 30px 0;
  }
`;

export const TestimonialsHeader = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 2.2rem;
  color: #563f7d;
  text-align: center;
  margin-bottom: 25px;
`;

export const Testimonial = styled.div`
  background: #fff;
  border-radius: 12px;
  margin: 0 30px;

  height: 160px;
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    margin: 0 40px;
    padding: 0 50px;
  }
`;

export const QuoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RatingContainer = styled.div`
  width: 110px;
  margin-bottom: 10px;

  div {
    padding: 0;
  }
`;

export const Quote = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.7rem;
  line-height: 2.1rem;
  color: #201234;
  margin-bottom: 10px;

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
    line-height: 3.2rem;
  }
`;

export const Source = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.4rem;
  font-weight: 200;
  color: #231539;

  @media only screen and (min-width: 768px) {
    font-size: 1.6rem;
  }
`;
