import React from 'react';
import { TextFieldContainer } from './Style';

const TextField = ({ children, ...rest }) => {
  return (
    <TextFieldContainer {...rest}>
      {children}
    </TextFieldContainer>
  );
};

export default TextField;
