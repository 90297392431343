import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../lib/useUser';

import { ReactComponent as IconProfile } from '../../assets/icon-profile.svg';
import { ReactComponent as IconLogin } from '../../assets/icon-login.svg';
import {
  HeaderContainer,
  HeaderInner,
  LogoLink,
  Logo,
  ProfileLink,
  LoginButton,
} from './Style';
import { logo } from '../../assets';

import { netlifyAuth } from '../../lib/netlifyAuth';

const Header = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const animationProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 800,
    config: { duration: 1200 },
  });

  const AnimatedLogoLink = animated(LogoLink);

  return (
    <HeaderContainer>
      <HeaderInner>
        <AnimatedLogoLink
          style={animationProps}
          onClick={() => {
            navigate(`/`);
          }}
        >
          <Logo src={logo} alt="Kidcast" />
        </AnimatedLogoLink>
        {user ? (
          <ProfileLink onClick={() => navigate('/profile')}>
            <IconProfile />
          </ProfileLink>
        ) : (
          <LoginButton onClick={() => netlifyAuth.authenticate()}>
            <IconLogin />
            <span>Login</span>
          </LoginButton>
        )}
      </HeaderInner>
    </HeaderContainer>
  );
};

export default Header;
