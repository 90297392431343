import React, { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';
import { useNavigate } from 'react-router-dom';

import { Footer, SocialLinks } from '../../components';
import { ConfirmationContainer, RegisterButton } from './Style';

import { useUserContext } from '../../lib/useUser';

const Confirmation = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const openRegistration = () => {
    netlifyIdentity.open('signup');
  };

  const openLogin = () => {
    netlifyIdentity.open('login');
  };

  useEffect(() => {
    window.fbq('track', 'Purchase', { value: 18.99, currency: 'AUD' });
    window.gtag('event', 'conversion', {
      send_to: 'AW-11079154439/s_fOCOSMjY0YEIf2-aIp',
      transaction_id: '',
    });
    if (user) {
      navigate('/profile');
    }
  }, [user, navigate]);

  return (
    <>
      <ConfirmationContainer>
        <h2>Thank you!</h2>

        <p>
          Your purchase of the Kidcast Starter Pack was successful and you'll
          receive your purchase receipt and welcome email shortly.
        </p>

        <p>
          To start playing your stories, please set a password by clicking the
          link below:
        </p>
        <RegisterButton href="#signup" onClick={openRegistration}>
          Set Password
        </RegisterButton>
        <p>
          If you’ve already set a password,{' '}
          <a href="#login" onClick={openLogin}>
            click here to login
          </a>
        </p>

        <p>
          Didn’t receive an email? Please check your Promotions inbox then email{' '}
          <a href="mailto: hello@kidcast.fm">hello@kidcast.fm</a> and we’ll get
          right on it.
        </p>
        <SocialLinks />
      </ConfirmationContainer>
      <Footer />
    </>
  );
};

export default Confirmation;
