import React, { useEffect } from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import netlifyIdentity from 'netlify-identity-widget';

import {
  Home,
  About,
  Terms,
  Privacy,
  Play,
  Profile,
  Shop,
  Personalise,
  Confirmation,
  GiftPurchase,
  GiftConfirmation,
  GiftDownload,
  GiftRedemption,
} from '../../pages';

import { PrivateRoute } from '../';

const RouteContainer = () => {
  // check query string
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const queryStringLogin = searchParams.get('login');
    const queryStringSignup = searchParams.get('signup');

    if (queryStringLogin) {
      netlifyIdentity.open('login');
    }

    if (queryStringSignup) {
      netlifyIdentity.open('signup');
    }
  }, [searchParams]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/personalise" element={<Personalise />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/confirmation" element={<Confirmation />} />
      <Route path="/gift-purchase" element={<GiftPurchase />} />
      <Route path="/gift-confirmation" element={<GiftConfirmation />} />
      <Route path="/gift-download" element={<GiftDownload />} />
      <Route path="/gift-redeem" element={<GiftRedemption />} />
      <Route path="/play" element={<Play />} />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default RouteContainer;
