import styled from '@emotion/styled';

export const PlainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fffdfc;
  padding: 20px;
  flex-grow: 1;

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }

  h2 {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #4f2747;
  }

  h3 {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold
    font-size: 1.6rem;
    color: #5a2b51;
    line-height: 2.3rem;
    margin: 30px 0 20px 0;
  }

  p {
    font-family: 'Quicksand', sans-serif;
    font-size: 1.5rem;
    color: #241539;
    line-height: 2.3rem;

    a {
        font-family: 'Quicksand', sans-serif;
        text-decoration: underline;
        color: #240f42;
    }
  }

  li {
    a {
        font-family: 'Quicksand', sans-serif;
        text-decoration: underline;
        color: #240f42;
    }
  }
`;
