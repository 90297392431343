import React from 'react';
import { SelectContainer } from './Style';

const Select = ({ children, ...rest }) => {
  return (
    <SelectContainer {...rest}>
      {children}
    </SelectContainer>
  );
};

export default Select;
