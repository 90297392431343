import styled from '@emotion/styled';

export const PlayPageWrapper = styled.div`
  background: #5f3492;
`;

export const PlayHeader = styled.div`
  padding: 20px 40px;
  text-align: center;
  background: #f4ecff;

  h1 {
    margin: 0;
    padding: 0;
    color: #722b65;
    font-size: 2.2rem;

    span {
      text-transform: capitalize;
    }
  }

  @media only screen and (min-width: 768px) {
    width: 768px;
    margin: 60px auto 0 auto;
  }
`;

export const PlayContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;

  @media only screen and (min-width: 768px) {
    height: calc(100vh - 192px);
  }

  ${(props) => {
    if (props.freeUserLayout === true) {
      return `
        padding-bottom: 0;
    `;
    }
  }}
`;
