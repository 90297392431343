import styled from '@emotion/styled';

export const FaqContainer = styled.div`
  padding: 30px 30px 10px 30px;
  background: white;

  @media only screen and (min-width: 768px) {
    padding: 0 40px 20px 40px;
  }
`;

export const AccordionContainer = styled.div`
  margin-bottom: 30px;

  h2 {
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 3.2rem;
    margin: 0 0 34px 0;
    color: #563f7d;
  }

  h3 {
    font-weight: bold;
    text-align: left;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }

  p {
    text-align: left;
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  ul {
    margin: 20px;
  }

  li {
    text-align: left;
    font-weight: normal;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin: 0 0 10px 0;
  }

  .MuiAccordionSummary-content {
    margin: 16px 8px 16px 0;
  }

  .MuiAccordionDetails-root {
    padding: 0 16px 8px 16px;
    margin-top: -10px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    svg {
      font-size: 2rem;
      color: #d3b8ff;
    }
  }
`;
