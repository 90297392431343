import styled from '@emotion/styled';
import { iconPlaySml } from '../../assets';

export const OtherStoriesContainer = styled.div`
  background: #f9f3ff;
  padding: 30px 20px 30px 20px;

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }
`;

export const OtherStoriesTitle = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #201237;
  text-align: center;
  line-height: 2.8rem;
  padding: 0 20px 20px 20px;
`;

export const OtherStoriesList = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  position: relative;

  border: 1px solid #eaeaea;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.36);
`;

export const OtherStoryItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #f1f1f1;
  padding: 20px 20px 10px 15px;

  &:first-of-type {
    border-top: none;
  }

  &.narrower {
    @media only screen and (min-width: 768px) {
      padding-right: 160px;
    }
  }
`;

export const OtherStoryThumb = styled.div`
  width: 50px;

  img {
    width: 50px;
  }
`;

export const OtherStoryText = styled.div`
  padding-left: 10px;

  h3 {
    font-size: 1.4rem;
    color: #5f3492;
    background: url('${iconPlaySml}') left no-repeat;
    padding: 0 0 0 22px;
    min-height: 16px;
    line-height: 16px;
    margin: 0;

    &.no-icon {
      background: none;
      padding: 0;
    }
  }

  p {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #645674;

    @media only screen and (min-width: 768px) {
      font-size: 1.5rem;
      line-height: 2.3rem;
    }
  }
`;

export const OtherStoriesFeatureImage = styled.div`
  position: absolute;
  bottom: -2px;
  right: -40px;
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;
