import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import 'react-h5-audio-player/lib/styles.css';
import { capitaliseName } from '../../lib/utils';
import {
  AudioPreviewContainer,
  StyledAudioPlayer,
  PreviewDescription,
  PreviewText,
} from './Style';

const AudioPreview = ({
  selectedAudio,
  selectedName,
  selectedStory,
  onEndedCallback,
}) => {
  const [machineId, setMachineId] = useState('');

  // play tracking
  const sendPlayNotification = async () => {
    window.gtag('event', 'play_audio_' + selectedStory.replaceAll('-', '_'), {
      event_category: 'audio_player',
      event_label: selectedName,
    });

    const data = {
      played_for: selectedName,
      story: selectedStory + '-preview',
      machineId: machineId,
    };

    const response = await fetch('/.netlify/functions/play-story', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    console.log(response);
  };

  // function to get machine ID for play tracking
  const getMachineId = () => {
    let returnMachineId = localStorage.getItem('machineId');
    if (!returnMachineId) {
      returnMachineId = uuidv4();
      localStorage.setItem('machineId', returnMachineId);
    }
    return returnMachineId;
  };

  // grab ID on load
  useEffect(() => {
    setMachineId(getMachineId());
  }, []);

  return (
    <AudioPreviewContainer>
      <StyledAudioPlayer
        // autoPlay
        layout="stacked-reverse"
        listenInterval={10000}
        src={selectedAudio}
        onPlay={(e) => sendPlayNotification()}
        onPause={(e) =>
          window.gtag(
            'event',
            'pause_audio_' + selectedStory.replaceAll('-', '_'),
            {
              event_category: 'audio_player',
              event_label: Math.floor(e.target.currentTime),
            }
          )
        }
        onEnded={onEndedCallback}
        onSeeking={(e) =>
          window.gtag(
            'event',
            'seek_audio_' + selectedStory.replaceAll('-', '_'),
            {
              event_category: 'audio_player',
              event_label: Math.floor(e.target.currentTime),
            }
          )
        }
        showJumpControls={false}
        customVolumeControls={[]}
        customControlsSection={[
          'MAIN_CONTROLS',
          <PreviewDescription>
            <PreviewText>
              {selectedName ? (
                <h2>{capitaliseName(selectedName)}'s Free Story</h2>
              ) : (
                <h2>Preview</h2>
              )}
              {selectedName ? (
                <p>
                  Press play to listen to {capitaliseName(selectedName)}'s free
                  personalised story!
                </p>
              ) : (
                <p>Select a name to load preview</p>
              )}
            </PreviewText>
          </PreviewDescription>,
        ]}
        customProgressBarSection={['CURRENT_TIME', 'PROGRESS_BAR']}
      />
    </AudioPreviewContainer>
  );
};

export default AudioPreview;
