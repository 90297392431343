import styled from '@emotion/styled';

export const HomeBannerContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  position: relative;

  video {
    height: 100%;
    object-fit: cover;
    background: #f8f3ff;
  }
`;

export const BannerContent = styled.div`
  width: 100%;
  height: 500px;
  z-index: 99;
  position: absolute;
  margin: 0 auto;
  background: none;
`;

export const VideoGradient = styled.div`
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.73) 99%
  );
  z-index: 88;
  position: absolute;
  width: 100%;
  height: 500px;
`;

export const TagLineContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 0 30px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    padding: 0 50px;
  }
`;

export const TagLine = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 2.4rem;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
`;

export const SubTag = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #ffffff;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
`;
