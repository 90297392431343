import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionContainer from '../AccordionContainer';
import { GiftFaqContainer } from './Style';
import { exampleCertificate } from '../../assets';

const GiftFaq = () => {
  return (
    <GiftFaqContainer>
      <h2>Gift Certificate FAQ</h2>

      <AccordionContainer>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-1">
            <h3>When do I receive the gift certificate?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Instantly! After checkout, we’ll send you an email to download
              your gift certificate. Then simply print, forward or share the
              certificate with the recipient however you like.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-2">
            <h3>What does the gift certificate look like?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              The certificate is designed to be printable and shareable so you
              can slip it into a card or forward it on electronically. It does
              not display the dollar value of the gift. You can see an example
              certificate here.
            </p>
            <img
              src={exampleCertificate}
              alt="Example Gift Certificate"
              width="100%"
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-3">
            <h3>Can I print the gift certificate out?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Yes! The gift certificate is designed to be printed and slipped
              into a card.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-4">
            <h3>How will they redeem their gift certificate?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              They simply scan the QR code or tap the link on the certificate to
              redeem their stories on Kidcast and play them instantly!
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-5">
            <h3>How soon can they play their stories?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Instantly! After they redeem their stories using the QR code or
              unique link on the gift certificate, their stories are available
              to play. There’s no app to install - all stories are playable from
              any device, any time.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-6">
            <h3>How do they play the stories?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              The stories play from any device directly through Kidcast.fm.
              After they redeem their gift certificate, all they have to do is
              press play! We also email them a link to access the stories. There
              are no apps to install or hoops to jump through. The stories will
              also play automatically through the car stereo or any external
              speaker connected to their device.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-7">
            <h3>How are the stories personalised? Is it a cloned voice?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              No, it’s 100% human. The human connection is absolutely critical
              to children’s storytelling and learning so we’ve built Kidcast to
              enhance this rather than imitate it. Every name is carefully
              recorded and we use our own technology to personalise each story.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="gift-faq-8">
            <h3>What are the stories about?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Each story is an opportunity for children to embark on an
              adventure to discover new things, solve problems, take risks and
              have fun. The stories feature the child's name, immersive sound
              design and innovative storytelling techniques to engage them in a
              positive way and encourage healthier learning, development and
              behaviour all based on the learning outcomes from Australia's
              nationally-approved learning frameworks.
            </p>
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>
    </GiftFaqContainer>
  );
};

export default GiftFaq;
