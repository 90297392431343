import styled from '@emotion/styled';
import { Autocomplete } from '@mui/material';
import {
  iconCircleCheck,
  iconSpeaker,
  desktopNameBannerBG,
} from '../../assets';

export const NameBannerOuterContainer = styled.div`
  background: #321961;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 70px);

  @media only screen and (min-width: 768px) {
    background: url('${desktopNameBannerBG}') center no-repeat;
    background-color: #321961;
    height: calc(100vh - 70px);
  }
`;

export const NameBannerContainer = styled.div`
  width: 100%;
  text-align: left;
  padding: 40px;

  .MuiAutocomplete-popper {
    .MuiPaper-elevation {
      background: #fffdf8;
      border-radius: 12px;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      color: #cd6d16;
      box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    }
  }

  @media only screen and (min-width: 768px) {
    width: 768px;
  }
`;

export const BannerIntroText = styled.div`
  width: 100%;
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 2.8rem;
  line-height: 3.6rem;
  color: #ffe0c8;
  text-align: left;
  margin-bottom: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 3.6rem;
    margin-bottom: 40px;
  }
`;

export const BannerNameText = styled.div`
  position: relative;
  height: 75px;
  border-bottom: 6px dashed #ffe4cd;

  @media only screen and (min-width: 768px) {
    height: 95px;
    margin-bottom: 20px;
  }
`;

export const TypedTextContainer = styled.div`
  width 100%;
  padding-bottom: 10px;
  position: absolute;

  .typed-cursor {
    font-size: 4.4rem;
    color: #D62E6F;

    @media only screen and (min-width: 768px) {
      font-size: 7.2rem;
    }
  }

  display: ${(props) => (props.typing ? 'block' : 'none')};
`;

export const TypedText = styled.span`
  font-size: 4.4rem;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  color: #d62e6f;
  line-height: 60px;

  @media only screen and (min-width: 768px) {
    font-size: 7.2rem;
    line-height: 30px;
  }
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  transition: margin 0.5s;

  @media only screen and (min-width: 768px) {
    height: 90px;
    margin-top: -15px;
  }

  .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiAutocomplete-inputRoot {
    border: none;
    background: none;
    font-family: 'Quicksand', sans-serif;
    height: 60px;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    color: #563079;
    font-size: 4.4rem;
    font-weight: bold;
    font-family: 'Quicksand', sans-serif;
    color: #d62e6f;

    @media only screen and (min-width: 768px) {
      font-size: 7.2rem;
      line-height: 30px;
      height: 90px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    ::placeholder {
      font-weight: 300;
      color: #67538d;
    }
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  &.waiting {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 5px;
      width: 60px;
      height: 60px;
      background: url('${iconSpeaker}') center no-repeat;
      background-size: 50%;

      @media only screen and (min-width: 768px) {
        top: 10px;
        width: 80px;
        height: 80px;
      }
    }
  }

  &.complete {
    .MuiAutocomplete-inputRoot {
      color: #90e3b7;
      position: relative;
    }

    position: relative;

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 5px;
      width: 60px;
      height: 60px;
      background: url('${iconCircleCheck}') center no-repeat;
      background-size: 50%;

      @media only screen and (min-width: 768px) {
        top: 10px;
        width: 80px;
        height: 80px;
      }
    }
  }
`;

export const NameSelectionContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 99;
`;

export const NameHelpContainer = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #b5abc4;
  max-height: ${(props) => (props.isCollapsed ? 0 : '120px')};
  overflow: hidden;
  -webkit-transition: max-height 0.7s ease-out;
  -moz-transition: max-height 0.7s ease-out;
  -ms-transition: max-height 0.7s ease-out;
  -o-transition: max-height 0.7s ease-out;
  transition: max-height 0.7s ease-out;

  @media only screen and (min-width: 768px) {
    font-size: 2.2rem;
    line-height: 3rem;
  }

  span {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    color: #decaff;
  }
`;

export const NameHelpText = styled.div`
  padding: 30px 0 0 0;
`;

export const EmailContainer = styled.div`
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;

  &.active {
    opacity: 1;
  }
`;
