import styled from '@emotion/styled';

export const PoweredByMonikerrContainer = styled.div`
  background: #170c29;
  width: 100%;
  padding: 23px;
  font-size: 11px;
  font-weight: bold;
  color: #bebebe;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 17px;
    margin-left: 10px;
  }
`;
