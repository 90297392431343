import React from 'react';
import { BenefitsContainer, Benefit } from './Style';

const BenefitsKids = () => {
  return (
    <BenefitsContainer>
      <h2>How do kids benefit from Kidcast?</h2>
      <Benefit>
        <h3>💡 Inspire Creativity</h3>
        <p>
          Ignites the imagination and helps unleash each child's creative
          potential. Whether it’s drawing, storytelling, craft, Lego, playing
          with toys or playing with other children, our immersive stories
          encourage children to experiment with new ideas and express themselves
          more confidently.
        </p>
      </Benefit>
      <Benefit>
        <h3>📚 Strengthens Learning</h3>
        <p>
          Kidcast stories are based on Australia’s nationally-approved learning
          frameworks to help improve literacy, vocabulary, fluency and focus,
          and are used by educators in classrooms and kindergartens to help
          children become better learners.
        </p>
      </Benefit>
      <Benefit>
        <h3>📺 Screen-free Entertainment</h3>
        <p>
          We'd all rather see our child pick up a book instead of a device, but
          entertaining young kids is tough and screens have their place. Kidcast
          captures your child's attention as well as YouTube or playing games,
          but with healthy, educational content that gets them using their
          imaginations to stay entertained.
        </p>
      </Benefit>
    </BenefitsContainer>
  );
};

export default BenefitsKids;
