import React from 'react';
import { useSpring, animated } from 'react-spring';
import { ContentContainer } from './Style';

const Content = ({ children }) => {

  const animationProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1200 },
  });

  const AnimatedContentContainer = animated(ContentContainer);

  return (
    <AnimatedContentContainer style={animationProps}>{children}</AnimatedContentContainer>
  );
}

export default Content;
