import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { CardContainer, CloseLink } from './Style';
import { ReactComponent as IconClose } from '../../assets/icon-close.svg';

const Card = ({ children }) => {
  const navigate = useNavigate();
  const animationProps = useSpring({
    from: { opacity: 0, marginTop: 10 },
    to: { opacity: 1, marginTop: 0 },
    config: { duration: 800 },
  });

  const AnimatedCardContainer = animated(CardContainer);

  return (
    <AnimatedCardContainer style={animationProps}>
      <CloseLink onClick={() => navigate(-1)}>
        <IconClose />
      </CloseLink>
      {children}
    </AnimatedCardContainer>
  );
}

export default Card;
