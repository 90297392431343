import styled from '@emotion/styled';

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  background: #fff;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 768px;
    padding: 20px 30px 60px 30px;
  }
`;

export const ShareContainerInner = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const ShareContainerContent = styled.div`
  @media only screen and (min-width: 768px) {
    padding-right: 30px;
  }
`;

export const ShareContainerImage = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;

    img {
      width: 310px;
      align-self: center;
    }
  }
`;

export const ShareFromContainer = styled.div`
  width: 100%;

  &.hidden {
    display: none;
  }
`;

export const ShareTitle = styled.h2`
  color: white;
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: center;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 20px;
  z-index: 9;
  color: #44325a;

  @media only screen and (min-width: 768px) {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 40px;
  }
`;

export const ShareTextContainer = styled.div`
  margin-bottom: 10px;

  p {
    color: #443058;
    font-size: 1.4rem;
    line-height: 2.1rem;
    text-align: left;
    font-weight: normal;
    font-family: 'Quicksand', sans-serif;

    @media only screen and (min-width: 768px) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
`;
