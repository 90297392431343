import styled from '@emotion/styled';

export const BenefitsContainer = styled.div`
  padding: 20px 30px 0 30px;
  background: #f9f3ff;

  h2 {
    font-size: 2rem;
    line-height: 2.8rem;
    text-align: center;
    color: #44325a;
  }

  @media only screen and (min-width: 768px) {
    padding: 20px 40px 10px 40px;
  }
`;

export const Benefit = styled.div`
  padding: 15px 0;
  margin: 15px 0;
  border-bottom: 3px dashed #dacdee;
  text-align: left;

  h3 {
    font-size: 1.6rem;
    color: #4e3866;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.4rem;
    color: #4e3866;
    line-height: 2.3rem;
  }

  &:last-child {
    border-bottom: none;
  }
`;
