import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionContainer from '../AccordionContainer';
import { Button } from '../../components';
import { ProductFaqContainer, ProductFaqFooter } from './Style';

const ProductFaq = () => {
  const navigate = useNavigate();
  return (
    <ProductFaqContainer>
      <h2>Product Details</h2>

      <AccordionContainer>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-1">
            <h3>Who is Kidcast?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              We are the world's first online audio platform that plays stories
              with your child as the star! Kidcast was founded in 2022 by
              best-selling children's author, Adam Kershaw.{' '}
              <Link to="/about-us">
                Click here to learn more about our story.
              </Link>
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-2">
            <h3>What makes Kidcast stories special?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Every Kidcast story uses your child's name to bring them into the
              adventure as the main character! Our stories are specifically
              designed to bring the imagination to life and entertain kids from
              2-6 years without screens or distractions.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-3">
            <h3>How do I play Kidcast stories?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Enter your child's name and their story will play instantly! You
              can also save their story for later and we'll email the link to
              you. Kidcast stories play from any device and you can access them
              from anywhere, anytime.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-4">
            <h3>How do I buy Kidcast stories?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              The first Kidcast story is yours for free and there's no limit on
              how many times you can play it. There are also 7 more stories
              available to purchase and play instantly from only AUD $4.99. The
              additional stories can be played an unlimited amount of times and
              you can access them from any device, anytime.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-5">
            <h3>How do you personalise all the stories?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Every Kidcast story is personalised with a human voice and we have
              spent countless hours developing our own process and technology to
              record and personalise each story. Although clone voices are
              becoming more and more realistic, the human connection is
              absolutely critical to children's storytelling so we've built
              Kidcast to enhance this rather than imitate it.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-6">
            <h3>When do I play Kidcast stories to my child?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              You can play Kidcast stories anytime! Each story is designed to
              capture a child's attention and be played over-and-over again.
              They make perfect bedtime stories, a fun inclusion to your regular
              storytime, great screen-free entertainment in the car or during
              meals, and a unique gift for kids.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-7">
            <h3>What are the stories about?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Each Kidcast story is designed to take children on an adventure
              where they discover new things, solve problems, take risks and
              have fun. From playing in the rain to baking in the kitchen, the
              stories are age-appropriate and relatable for children 2-6 years.
            </p>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="product-faq-8">
            <h3>Are the stories educational?</h3>
          </AccordionSummary>
          <AccordionDetails>
            <p>
              Yes! Every Kidcast story is designed to enrich learning based on
              the five learning outcomes from the Early Years Learning Framework
              for Australia. They're also an excellent resource for young
              children learning English as a second language.
            </p>
          </AccordionDetails>
        </Accordion>
      </AccordionContainer>

      <ProductFaqFooter>
        <Button
          variation="alternative"
          variant="contained"
          onClick={() => {
            window.gtag('event', 'click_learn_more', {
              event_category: 'landing_screen',
              event_label: 'Learn More',
            });
            navigate(`about-us`);
          }}
        >
          Click here to learn more
        </Button>
      </ProductFaqFooter>
    </ProductFaqContainer>
  );
};

export default ProductFaq;
