import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export const ProfileContainer = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;

  @media only screen and (min-width: 768px) {
    padding: 60px 30px;
    width: 768px;
  }
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
`;

export const ProfileContent = styled.div`
  p {
    margin: 20px 0 10px 0;
    font-size: 1.6rem;
    line-height: 2.3rem;

    a {
      font-family: 'Quicksand', sans-serif;
      text-decoration: underline;
      color: #240f42;
    }
  }
`;

export const ProfileTitle = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: #240f42;
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;

export const LogoutButton = styled.a`
  color: #240f42;
  font-weight: bold;
  text-decoration: none;
`;

export const CollectionLinks = styled.div`
  padding: 20px 0;
`;

export const CollectionItem = styled(Link)`
  background: #896dab;
  height: 50px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  margin: 0 0 2px 0;
  text-decoration: none;
  justify-content: space-between;

  span {
    font-family: 'Quicksand', sans-serif;
    font-weight: bold;
    font-size: 1.8rem;
    color: #fff;
  }
`;
