import React, { useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget';

import { Link } from 'react-router-dom';

import { Footer } from '../../components';

import { useUserContext } from '../../lib/useUser';
import {
  ProfileWrapper,
  ProfileContainer,
  ProfileHeader,
  ProfileContent,
  ProfileTitle,
  LogoutButton,
  CollectionLinks,
  CollectionItem,
} from './Style';

import { ReactComponent as IconCollections } from '../../assets/icon-collections.svg';
import { ReactComponent as IconPlaySmall } from '../../assets/icon-play-sml-light.svg';

const Profile = () => {
  const { userPurchases, purchasesLoading, user, userSubscribedFor } =
    useUserContext();

  const logout = () => {
    netlifyIdentity.logout();
  };

  // first time check if user is saving a story then subscribe them
  useEffect(() => {
    if (localStorage.getItem('savingStoryFor')) {
      // subscribe this user so we can use their subscribed email
      // and 'saved for' selected name as their saved free story
      const subscribe = async () => {
        const data = {
          email: user.email,
          story_name: localStorage.getItem('savingStoryFor'),
        };

        await fetch('/.netlify/functions/subscribe', {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        });
      };

      subscribe().then((res) => {
        localStorage.removeItem('savingStoryFor');
      });
    }
  }, [user]);

  return (
    <ProfileWrapper>
      <ProfileContainer>
        <ProfileHeader>
          <ProfileTitle>
            Welcome, {user ? user.user_metadata.full_name : 'dear listener'}!
          </ProfileTitle>
          <LogoutButton href="#logout" onClick={logout}>
            Log out
          </LogoutButton>
        </ProfileHeader>

        <ProfileContent>
          <ProfileTitle>
            <IconCollections /> My Collections
          </ProfileTitle>
          {userPurchases && userPurchases.length === 0 && userSubscribedFor && (
            <>
              <p>You don't have any purchased stories yet.</p>
              <p>
                You can access your saved free story by{' '}
                <Link
                  to={
                    `/play?selected_name=` + userSubscribedFor[0].subscribed_for
                  }
                >
                  clicking here.
                </Link>
              </p>
            </>
          )}
          {purchasesLoading ? (
            <p>Loading...</p>
          ) : (
            <CollectionLinks>
              {userPurchases &&
                userPurchases.map(function (item) {
                  return (
                    <CollectionItem
                      key={item.name}
                      to={`/play?selected_name=` + item.name}
                    >
                      <span>{item.name}'s Stories</span>
                      <IconPlaySmall />
                    </CollectionItem>
                  );
                })}
            </CollectionLinks>
          )}
        </ProfileContent>
      </ProfileContainer>
      <Footer />
    </ProfileWrapper>
  );
};

export default Profile;
