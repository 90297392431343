import styled from '@emotion/styled';

export const CollectionFaqContainer = styled.div`
  padding: 20px 20px 0 20px;
  background: #231539;

  h2 {
    font-size: 2rem;
    color: #d9bfff;
    text-align: center;
    line-height: 2.8rem;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 40px 10px 40px;
  }
`;
