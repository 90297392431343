import React from 'react';
import { iconStar } from '../../assets';
import { RatingContainer } from './Style';

const Rating = ({ stars }) => {
  const starsOutput = [];
  for (let i = 0; i < stars; i++) {
    starsOutput.push(<img src={iconStar} key={i} alt="star" />);
  }
  return <RatingContainer>{starsOutput}</RatingContainer>;
};

export default Rating;
