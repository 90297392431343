import styled from '@emotion/styled';

export const CollectionReviewsContainer = styled.div`
  padding: 20px;
  background: #fffdfc;

  h2 {
    font-size: 2rem;
    color: #44325a;
    text-align: center;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    padding: 30px 40px;
  }
`;

export const CollectionReview = styled.div`
  border-bottom: 1px solid #e7e7e7;
  padding: 25px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const ReviewTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const ReviewName = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: bold;
  color: #443058;
`;

export const ReviewText = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  line-height: 2.3rem;
  color: #443058;
`;
