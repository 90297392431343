import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9;
  height: 70px;
  background: #f8f3ff;
`;

export const HeaderInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }

  @media only screen and (min-width: 768px) {
    width: 768px;
  }
`;

export const LogoLink = styled.a`
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 71px;
`;

export const ProfileLink = styled.div`
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 20px;
`;

export const LoginButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ecdeff;
  border-radius: 7px;
  padding: 4px 7px 4px 5px;

  span {
    font-size: 1.4rem;
    font-weight: 500;
  }
`;
