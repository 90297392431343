import styled from '@emotion/styled';

export const FeatureImageAndTextContainer = styled.div`
  background: #ecdeff;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 30px 30px 0 30px;

  @media only screen and (min-width: 768px) {
    margin: 60px 0;
    width: 768px;
    background: #fff2e0;
    border-radius: 15px;
    padding: 0;
  }
`;

export const FeatureImage = styled.div`
  img {
    width: 100px;
  }
`;

export const ImageSmall = styled.div`
  display: block;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const ImageLarge = styled.div`
  display: none;
  width: 371px;
  img {
    width: 371px;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;

export const FeatureText = styled.div`
  text-align: left;
  padding-left: 20px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    text-align: center;
    padding: 0 60px;
  }

  h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin: 0 0 5px 0;
    color: #53397c;

    @media only screen and (min-width: 768px) {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }

  p {
    line-height: 2.4rem;
    margin: 0;
    width: 100%;
    color: #53397c;
  }
`;
