import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import netlifyIdentity from 'netlify-identity-widget';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { availableNames } from '../../lib/availableNames';
import { useUserContext } from '../../lib/useUser';

import {
  TextField,
  PlainContainer,
  Button,
  Notify,
  Footer,
  PoweredByMonikerr,
} from '../../components';

import {
  RedemptionDetails,
  RedemptionForm,
  StyledAutocomplete,
  ButtonContainer,
  ErrorContainer,
} from './Style';

const GiftRedemption = () => {
  const { user } = useUserContext();
  const [searchParams] = useSearchParams();

  const queryStringCode = searchParams.get('code');
  const queryStringPurchase = searchParams.get('purchase');
  const queryStringRecord = searchParams.get('record');

  const [selectedName, setSelectedName] = useState(null);
  const [enteredName, setEnteredName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [nameUnavailable, setNameUnavailable] = useState(false);

  const [displayError, setDisplayError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [displayProfileSetup, setDisplayProfileSetup] = useState(false);
  const [redeemLoading, setRedeemLoading] = useState(false);

  // redeem gift in backend
  const redeemGiftCardRequest = async () => {
    const data = {
      record_id: queryStringRecord,
      selected_name: selectedName,
      stripe_id: queryStringCode,
      purchase_record: queryStringPurchase,
      customer_email: emailAddress,
    };
    setRedeemLoading(true);

    const response = await fetch('/.netlify/functions/gift-redeem', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const redemption = await response.json();

    if (!response.ok) {
      const message = `An error has occured: ${redemption.message}`;
      throw new Error(message);
    }

    return redemption;
  };

  const handleNameInputOpen = (event, value) => {
    setNameUnavailable(false);
  };

  const handleNameInputChange = (event, value) => {
    // Get the filtered options for the current input value
    const filteredOptions = availableNames.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );

    // If there are no filtered options, log a message
    if (filteredOptions.length === 0) {
      console.log(`No options available for "${value}"`);
      setEnteredName(value);
      setNameUnavailable(true);
    }
  };

  const handleNameSelect = (event, value) => {
    setSelectedName(value);
    setNameUnavailable(false);
  };

  const handleNameSelectClose = (event, value) => {
    //only on blur and name request visible true, clear the input
    if (value === 'blur' && nameUnavailable) {
      setSelectedName(null);
    }
  };

  const redeemGiftCard = () => {
    if (selectedName && emailAddress) {
      setDisplayError(false);
      setErrorMessage('Please make sure all fields are completed above.');
      redeemGiftCardRequest()
        .then((res) => {
          setErrorMessage('');
          setRedeemLoading(false);
          setDisplayError(false);

          // display profile setup OR redirect if user already logged in
          if (user) {
            window.location.href = '/profile';
          } else {
            setDisplayProfileSetup(true);
          }
        })
        .catch((error) => {
          setRedeemLoading(false);
          setDisplayError(true);
          setErrorMessage(error.message);
        });
    } else {
      setDisplayError(true);
      if (enteredName && !selectedName) {
        setErrorMessage(
          'Cannot redeem for a name not yet available, please request the name and we will email you as soon as it is ready.'
        );
      } else {
        setErrorMessage('Please make sure all fields are completed above.');
      }
    }
  };

  // open registration form
  const openRegistration = () => {
    netlifyIdentity.open('signup');
  };

  return (
    <>
      <Helmet>
        <title>Gift Redemption | Kidcast</title>
      </Helmet>
      <PlainContainer>
        <RedemptionDetails>
          {displayProfileSetup ? (
            <>
              <h2>Finish Creating Your Account</h2>
              <p>
                Click the link below to set your password and start playing your
                stories. Once you’ve finished creating your account, you can
                access your stories from anywhere, on any device by logging in
                to your Kidcast account.
              </p>
            </>
          ) : (
            <>
              <h2>Kidcast Starter Pack Gift Redemption</h2>
              <p>
                Welcome to Kidcast! You’ve received the Kidcast Starter Pack, which
                includes 8 personalised audio stories for your child you can
                play anywhere, from any device.
              </p>
              <p>
                To start playing your stories, simply enter your child’s name,
                your email address and the gift code below. For more information
                about Kidcast, <a href="https://kidcast.fm/about">click here</a>
                .
              </p>
              <p>
                Having trouble redeeming your gift? Please email{' '}
                <a href="mailto:hello@kidcast.fm">hello@kidcast.fm</a> and we’ll{' '}
                get right on it.
              </p>
            </>
          )}
        </RedemptionDetails>
        <RedemptionForm>
          {displayProfileSetup ? (
            <>
              <ButtonContainer>
                <Button variant="contained" onClick={openRegistration}>
                  Set Password
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <>
              <h3>Child's Name</h3>
              <StyledAutocomplete
                disablePortal
                clearOnBlur
                id="child-name"
                options={availableNames}
                onOpen={handleNameInputOpen}
                onInputChange={handleNameInputChange}
                onChange={handleNameSelect}
                onClose={handleNameSelectClose}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Child's name..." />
                )}
                noOptionsText="We don't have that name yet, use the form below to request this name."
                value={selectedName}
              />
              {nameUnavailable && (
                <Notify
                  notifyContainerClass="secondary"
                  enteredName={enteredName}
                />
              )}
              <h3>Your Email</h3>
              <TextField
                id="email"
                name="email"
                placeholder="Enter your email address..."
                type="email"
                onChange={(event) => {
                  setEmailAddress(event.target.value);
                }}
              />
              <h3>Gift Code</h3>
              <TextField
                id="gift-code"
                name="gift-code"
                placeholder="Enter the gift code..."
                value={queryStringCode + '_' + queryStringPurchase}
              />
              {displayError && <ErrorContainer>{errorMessage}</ErrorContainer>}

              <ButtonContainer>
                {redeemLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button variant="contained" onClick={redeemGiftCard}>
                    Redeem Gift
                  </Button>
                )}
              </ButtonContainer>
            </>
          )}
        </RedemptionForm>
      </PlainContainer>
      <Footer />
      <PoweredByMonikerr />
    </>
  );
};

export default GiftRedemption;
