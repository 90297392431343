import styled from '@emotion/styled';

export const FormTitleContainer = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: 400;
  color: white;
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  margin: 0 0 2rem 0;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 9;

  &.hidden {
    opacity: 0;
  }

  a {
    color: white;
  }
`;
