import { Global } from '@emotion/react';
import { HelmetProvider } from 'react-helmet-async';

import reset from './style/reset';
import fonts from './style/fonts';
import { base } from './style/base';

import {
  MainContainer,
  Header,
  Content,
  RouteContainer,
  ScrollToTop,
} from './components';

function App() {
  return (
    <HelmetProvider>
      <Global styles={[fonts, reset, base]} />
      <MainContainer>
        <ScrollToTop />
        <Content>
          <Header />
          <RouteContainer />
        </Content>
      </MainContainer>
    </HelmetProvider>
  );
}

export default App;
