import styled from '@emotion/styled';

export const PageTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  text-align: center;
  background: #241539;

  h1 {
    margin: 0;
    padding: 0;
    color: #ffe0c8;
    font-size: 2.2rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    span {
      text-transform: capitalize;
    }
  }
`;
