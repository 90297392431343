import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { stories } from '../../lib/stories';
import {
  PlaylistContainer,
  ListItem,
  ListThumb,
  ListTitle,
  Collection,
  CollectionTitle,
  CollectionList,
} from './Style';

const Playlist = ({ selectedStory, selectedName }) => {
  const [activeListItem, setActiveListItem] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectStory = (listItem) => {
    setActiveListItem(listItem.currentTarget.id);
    searchParams.set('selected_name', selectedName);
    searchParams.set('selected_story', listItem.currentTarget.id);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setActiveListItem(selectedStory);
  }, [selectedStory]);

  return (
    <PlaylistContainer>
      <Collection>
        <CollectionTitle>All Stories</CollectionTitle>
        <CollectionList>
          {stories.map((story) => (
            <ListItem
              key={story.id}
              id={story.id}
              onClick={selectStory}
              className={`${activeListItem === story.id && 'active'}`}
            >
              <ListThumb>
                <img src={story.thumb} alt={story.title} />
              </ListThumb>
              <ListTitle>{story.title}</ListTitle>
            </ListItem>
          ))}
        </CollectionList>
      </Collection>
    </PlaylistContainer>
  );
};

export default Playlist;
