import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Buy, BuyButtonContainer } from '../BuyButton/Style';

const BuyGiftButton = ({ quantity }) => {
  const [buyLoading, setBuyLoading] = useState(false);

  // call to get checkout function
  const getCheckoutRequest = async () => {
    const data = {
      selected_product:
        process.env.REACT_APP_STRIPE_COLLECTION_1_GIFT_APRIL_2023,
      quantity: quantity,
    };

    const fetchFunction = await fetch(
      '/.netlify/functions/create-gift-checkout',
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    const fetchResponse = await fetchFunction;

    return fetchResponse.json();
  };

  // buy btn click handler
  const getCheckout = () => {
    setBuyLoading(true);
    window.fbq('track', 'InitiateCheckout');
    getCheckoutRequest().then((res) => {
      window.location.href = res.sessionUrl;
    });
  };

  return (
    <BuyButtonContainer>
      {buyLoading ? (
        <CircularProgress color="secondary" />
      ) : (
        <Buy href="#" onClick={getCheckout}>
          Buy {quantity} Gift Certificate
        </Buy>
      )}
    </BuyButtonContainer>
  );
};

export default BuyGiftButton;
