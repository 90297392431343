import styled from '@emotion/styled';

export const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-end;
  padding: 10px 0;

  img {
    width: 19px;
  }

  @media only screen and (min-width: 420px) {
    padding: 0;
  }
`;
