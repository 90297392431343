import styled from '@emotion/styled';

export const FormTextContainer = styled.div`
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-weight: 600;
  color: white;
  font-family: 'Quicksand', sans-serif;
  text-align: left;
  margin: 0 0 3rem 0;
  opacity: 1;
  transition: opacity 0.5s;
  z-index: 9;

  @media only screen and (min-width: 768px) {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }

  &.hidden {
    opacity: 0;
  }

  &.removed {
    display: none;
  }

  p {
    color: white;
  }

  a {
    color: white;
  }
`;
