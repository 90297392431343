import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FooterContainer, FooterPageLinks, FooterLink } from './Style';

const Footer = () => {
  const location = useLocation();
  const [footerSticky, setFooterSticky] = useState(false);

  useEffect(() => {
    if (location.pathname === '/play') {
      setFooterSticky(true);
    }
  }, [location]);

  return (
    <FooterContainer id="AnimatedFooter" sticky={footerSticky}>
      <FooterPageLinks>
        <FooterLink href="/about-us">About Us</FooterLink>
        <FooterLink href="/terms">Terms of Use</FooterLink>
        <FooterLink href="/privacy">Privacy Policy</FooterLink>
      </FooterPageLinks>
    </FooterContainer>
  );
};

export default Footer;
