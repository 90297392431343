import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { iconCircleCheck } from '../../assets';

export const TextFieldContainer = styled(TextField)`
  border: 1px solid #F8D1AE;
  border-radius: 12px;
  background: #FFFDF8;
  height: 60px;
  width: 100%;
  transition: margin 0.5s;
  margin-bottom: 23px;
  z-index: 9;

  .MuiOutlinedInput-input {
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #cd6d16;

    ::placeholder {
      font-weight: 300;
      color: #67538D;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  &.complete {
    margin-top: 0;
    border: 1px solid #67538D;
    box-shadow: 0 2px 24px 0 rgba(0,0,0,0.30);
    position: relative;

    &:after{
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      background: url('${iconCircleCheck}') center no-repeat;
    }

    .MuiOutlinedInput-input {
      color: #67538D;
    }
  }
`;
